import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../API/AuthContext';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Typography, Avatar, Container } from '@mui/material';
import { FaBars, FaTimes } from 'react-icons/fa';
import api from '../API/api';
import { ApiConfig } from '../config/api.config';

interface MenuItemType {
  name: string;
  path: string;
  icon?: string;
  action?: () => void;
}

const Navbar: React.FC = () => {
  const { isAuthenticated, role, userId, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [navBarAnchorEl, setnavBarAnchorEl] = useState<null | HTMLElement>(null);
  const [userData, setUserData] = useState<any>({});
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && userId) {
      api(`/api/users/${userId}`, 'get', {})
        .then(response => {
          if (response.status === 'ok') {
            setUserData(response.data);
          }
        })
        .catch(error => {
          console.error('Failed to fetch user data:', error);
        });
    }
  }, [isAuthenticated, userId]);

  const handleMenuToggle = (event: React.MouseEvent<HTMLElement>) => {
    setnavBarAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setnavBarAnchorEl(null);
    setIsMenuOpen(false);
  };

  const guestMenuItems: MenuItemType[] = [
    { name: 'Naslovna', path: '/' },
    { name: 'Blog', path: '/blog' },
    { name: 'Shop', path: '/shop' },
    { name: 'Prijavi se', path: '/login' }
  ];

  const userMenuItems: MenuItemType[] = [
    { name: 'Naslovna', path: '/' },
    { name: 'Blog', path: '/blog' },
    { name: 'Shop', path: '/shop' },
  ];

  const menuItems = isAuthenticated
    ? (role === 'admin' ? [...userMenuItems] : userMenuItems)
    : guestMenuItems;

    const getItemClassName = (path: string) => {
      return location.pathname === path
      ? 'relative inline-block after:absolute after:inline-block after:w-full after:h-px after:bg-current after:bottom-0 after:left-0 after:scale-x-100 after:origin-bottom-right after:transition-transform after:duration-300 after:ease-out'
      : 'relative inline-block after:absolute after:inline-block after:w-full after:h-px after:bg-current after:bottom-0 after:left-0 after:scale-x-0 after:origin-bottom-right hover:after:scale-x-100 hover:after:origin-bottom-left after:transition-transform after:duration-300 after:ease-out';
    };

  return (
    <AppBar position="sticky" style={{ backgroundColor: 'white', color: 'black', boxShadow:"none"}}>
      <Container>
        <Toolbar className='mx-auto flex justify-between items-center'>
          <Link to={"/"}>
          <Typography component="div" className='mx-auto flex justify-between items-center' sx={{ flexGrow: 1 }}>
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2 group">
                <div className="border-[2px] text-[14px] border-black pt-[0.10rem] pb-[0.10rem] pl-[0.35rem] pr-[0.35rem] text-center font-bold group-hover:bg-black group-hover:text-white">
                  N
                </div>
                <div className="flex flex-grow items-center text-[14px]">
                  <div className="flex justify-between w-full">
                    <span className="tracking-wide p-1 pl-0">E</span>
                    <span className="tracking-wide p-1">J</span>
                    <span className="tracking-wide p-1">R</span>
                    <span className="tracking-wide p-1">A</span>
                  </div>
                </div>
              </div>
            </div>
          </Typography></Link>
          <div className="hidden sm:hidden md:flex space-x-4 px-4 py-2 font-medium text-[11px] tracking-[.35em]">
            {menuItems.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                onClick={item.action}
                className={`uppercase ${getItemClassName(item.path)} focus:outline-none `}
              >
                {item.name}
              </Link>
            ))}
          </div>
          {isAuthenticated && (
            <div className='hidden md:flex'>
              <IconButton
                edge="end"
                onClick={handleMenuToggle}
                style={{ color: '#c59d5f' }}
              >
                <Avatar
                  alt="Profilna slika"
                  src={userData.profileImage ? `${ApiConfig.API_URL}/uploads/users/${userId}/avatar-${userData.profileImage}` : undefined}
                />
              </IconButton>
              <Menu
                anchorEl={navBarAnchorEl}
                open={Boolean(navBarAnchorEl)}
                onClose={handleMenuClose}
              >
                {role === 'admin' && (
                  <MenuItem onClick={handleMenuClose}>
                    <Link to="/admin" className="block w-full">Administracija</Link>
                  </MenuItem>
                )}
                <MenuItem onClick={handleMenuClose}>
                  <Link to="/profile" className="block w-full">Profil</Link>
                </MenuItem>
                <MenuItem onClick={() => {
                  logout();
                  handleMenuClose();
                }}>
                  <span className="block w-full">Odjavi se</span>
                </MenuItem>
              </Menu>
            </div>
          )}
          <div className="md:hidden">
            <IconButton onClick={handleMenuToggle} color="inherit">
            {isAuthenticated ? (
                    <Avatar
                      alt="Profilna slika"
                      src={userData.profileImage ? `${ApiConfig.API_URL}/uploads/users/${userId}/${userData.profileImage}` : undefined}
                    />
                  ) : (
                    <IconButton onClick={() => setIsMenuOpen(!isMenuOpen)} color="inherit">
                      {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </IconButton>
                  )}
            </IconButton>
            <Menu
              anchorEl={navBarAnchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
             
            >
              {menuItems.map((item) => (
                <MenuItem
                  key={item.name}
                  onClick={() => {
                    item.action && item.action();
                    handleMenuClose();
                  }}
                  component={Link}
                  to={item.path}
                  className="text-white hover:bg-gray-700"
                >
                  {item.name}
                </MenuItem>
              ))}
              {isAuthenticated && (
                <>
                  <MenuItem
                    component={Link}
                    to="/profile"
                    className="text-white hover:bg-gray-700"
                    onClick={handleMenuClose}
                  >
                    Profil
                  </MenuItem>
                  {role === 'admin' && (
                    <MenuItem
                      component={Link}
                      to="/admin"
                      className="text-white hover:bg-gray-700"
                      onClick={handleMenuClose}
                    >
                      Administracija
                    </MenuItem>
                  )}
                  <MenuItem
                    component={Link}
                    to="/"
                    className="text-white hover:bg-gray-700"
                    onClick={() => {
                      logout();
                      handleMenuClose();
                    }}
                  >
                    Odjavi se
                  </MenuItem>
                </>
              )}
            </Menu>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
