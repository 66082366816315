import React, { useEffect, useState } from 'react';
import api, { ApiResponse } from '../../API/api';
import { ApiConfig } from '../../config/api.config';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Switch,
  FormControlLabel
} from '@mui/material';
import { Hero } from '../../types/hero.type';

const HeroManagement: React.FC = () => {
  const [heroes, setHeroes] = useState<Hero[]>([]);
  const [selectedHero, setSelectedHero] = useState<Hero | null>(null);

  useEffect(() => {
    fetchHeroes();
  }, []);

  const fetchHeroes = async () => {
    const response: ApiResponse = await api('/api/hero', 'get', undefined, 'admin');
    if (response.status === 'ok') {
      setHeroes(response.data);
    }
  };

  const handleAddHero = async (hero: Hero, file: File) => {
    const formData = new FormData();
    formData.append('title', hero.title);
    formData.append('shortDescription', hero.shortDescription);
    formData.append('description', hero.description);
    formData.append('image', file);

    const response: ApiResponse = await api('/api/hero', 'post', formData, 'admin', { useMultipartFormData: true });
    if (response.status === 'ok') {
      fetchHeroes();
    }
  };

  const handleEditHero = async (hero: Hero) => {
    const response: ApiResponse = await api(`/api/hero/${hero.heroId}`, 'put', hero, 'admin');
    if (response.status === 'ok') {
      fetchHeroes();
    }
  };

  const handleDeleteHero = async (id: number) => {
    const response: ApiResponse = await api(`/api/hero/${id}`, 'delete', undefined, 'admin');
    if (response.status === 'ok') {
      fetchHeroes();
    }
  };

  const handleStatusChange = async (hero: Hero) => {
    const updatedHero = { ...hero, status: hero.status === 'active' ? 'inactive' : 'active' };
    const response: ApiResponse = await api(`/api/hero/${hero.heroId}`, 'put', updatedHero, 'admin');
    if (response.status === 'ok') {
      fetchHeroes();
    }
  };

  return (
    <div className="p-4 flex gap-3 flex-col ">
      <Typography variant="h4" gutterBottom >
        Hero konfiguracija
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setSelectedHero({ heroId: 0, title: '', shortDescription: '', description: '', imageUrl: '', status: 'inactive' })}
      >
        Dodaj novi
      </Button>
      <Grid container spacing={4} className="mt-4">
        {heroes.map((hero) => (
          <Grid item xs={12} md={6} lg={3} key={hero.heroId}>
            <Card>
              <CardMedia
                component="img"
                className='max-h-44'
                image={ApiConfig.API_URL + hero.imageUrl}
                alt={hero.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {hero.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {hero.shortDescription}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" onClick={() => setSelectedHero(hero)}>
                  Izmjeni
                </Button>
                <Button size="small" color="secondary" onClick={() => handleDeleteHero(hero.heroId)}>
                  Obriši
                </Button>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hero.status === 'active'}
                      onChange={() => handleStatusChange(hero)}
                      color="primary"
                    />
                  }
                  label={hero.status === 'active' ? 'aktivan' : 'neaktivan'}
                />
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {selectedHero && (
        <HeroForm
          hero={selectedHero}
          onSave={selectedHero.heroId === 0 ? handleAddHero : handleEditHero}
          onCancel={() => setSelectedHero(null)}
        />
      )}
    </div>
  );
};

interface HeroFormProps {
  hero: Hero;
  onSave: (hero: Hero, file: File) => void;
  onCancel: () => void;
}

const HeroForm: React.FC<HeroFormProps> = ({ hero, onSave, onCancel }) => {
  const [formHero, setFormHero] = useState<Hero>(hero);
  const [file, setFile] = useState<File | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormHero({ ...formHero, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setFormHero({ ...formHero, imageUrl: URL.createObjectURL(e.target.files[0]) });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (file) {
      onSave(formHero, file);
    } else {
      onSave(formHero, new File([], ''));
    }
  };

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>{hero.heroId === 0 ? 'Dodaj Hero' : 'Izmjeni Hero'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Naslov"
            name="title"
            value={formHero.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Kratki opis"
            name="shortDescription"
            value={formHero.shortDescription}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Opis"
            name="description"
            value={formHero.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <input type="file" name="image" onChange={handleFileChange} className="w-full mb-2 p-2 border" />
          <div className="flex gap-3 mt-4">
            <Button type="submit" variant="contained" color="primary" className="mr-2">
              Snimi
            </Button>
            <Button onClick={onCancel} variant="contained" color="secondary">
              Izađi
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default HeroManagement;
