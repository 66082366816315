import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Slider,
  Pagination,
  Rating,
  SelectChangeEvent,
} from '@mui/material';
import api, { ApiResponse } from '../../../API/api';
import { ApiConfig } from '../../../config/api.config';
import { FaCartPlus } from 'react-icons/fa';
import { useAuth } from '../../../API/AuthContext';
import { MdKeyboardDoubleArrowDown, MdKeyboardDoubleArrowUp } from 'react-icons/md';

interface Product {
  productId: number;
  name: string;
  price: number;
  productCategory: { name: string };
  reviews: {
    rating: number;
  }[];
  imageUrl: string;
  discount: {
    code: string;
    amount: string;
    description: string;
    startDate: string;
    endDate: string;
  } | null;
}

const Shopping: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState('productId');
  const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC');
  const [searchQuery, setSearchQuery] = useState('');
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [priceRange, setPriceRange] = useState<number[]>([0, 1000]);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { isAuthenticated, userId } = useAuth();

  const fetchProducts = async () => {
    try {
      const response: ApiResponse = await api(
        `/api/products/paginate?page=${page}&limit=${limit}&sortBy=${sort}&sortOrder=${order}&searchQuery=${searchQuery}&category=${selectedCategory}&minPrice=${priceRange[0]}&maxPrice=${priceRange[1]}`,
        'get',
        undefined,
        'guest'
      );
      setProducts(response.data.products);
      setTotalPages(Math.ceil(response.data.total / limit));

      const uniqueCategories = response.data.products
        .map((product: Product) => product.productCategory.name)
        .filter((value: string, index: number, self: string[]) => self.indexOf(value) === index);

      setCategories(uniqueCategories);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 768) {
      setFiltersVisible(true); 
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [page, limit, sort, order, searchQuery, selectedCategory, priceRange]);

  const handleAddToCart = async (productId: number) => {
    if (!isAuthenticated) {
      alert('Morate biti prijavljeni za dodavanje proizvoda u korpu.');
      return;
    }

    try {
      setIsSubmitting(true);
      const cartData = {
        productId: productId || 0,
        quantity: 1,
        userId: userId || 0,
      };
      await api('/api/cart', 'post', cartData);
      alert('Proizvod je uspješno dodan u korpu!');
    } catch (error) {
      console.error('Greška prilikom dodavanja u korpu:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setSort(event.target.value as string);
  };

  const handleOrderChange = (event: SelectChangeEvent<string>) => {
    setOrder(event.target.value as 'ASC' | 'DESC');
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
  };

  const handlePriceRangeChange = (event: Event, newValue: number | number[]) => {
    setPriceRange(newValue as number[]);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const calculatePriceWithoutTax = (price: number): number => {
    return price / (1 + 0.17);
  };

  const calculateDiscountedPrice = (price: number, discountAmount: string | null): number => {
    if (!discountAmount) return price;
    const amount = parseFloat(discountAmount);
    const priceWithoutTax = calculatePriceWithoutTax(price);
    const priceWithDiscount = priceWithoutTax - (priceWithoutTax * (amount / 100));
    return priceWithDiscount * (1 + 0.17);
  };

  const calculateAverageRating = (reviews: { rating: number }[]): number => {
    if (reviews.length === 0) return 0;
    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return totalRating / reviews.length;
  };

  return (
    <div className="container max-w-screen-lg mx-auto p-4 flex flex-wrap">
      {/* Filter Button for Small Screens */}
      <div className="flex md:hidden mb-4">
        <button
          className='bg-white text-black border-2 border-black pr-4 pl-4 pt-2 pb-2 uppercase hover:bg-black hover:text-white text-sm'
          onClick={() => setFiltersVisible(!filtersVisible)}
        >
          <div className='w-full'>{filtersVisible ? (<span className='flex flex-row items-center'>Sakrij filtere<MdKeyboardDoubleArrowUp className='ml-2'/></span>) : 
          (<span className='flex flex-row items-center'>Prikaži filtere<MdKeyboardDoubleArrowDown className='ml-2'/></span>)} </div>
        </button>
      </div>

      {/* Sidebar with Filters */}
      <div
        style={{
          display: filtersVisible ? 'flex' : 'none',
        }}
        className="w-full md:w-1/4 p-4 gap-3 flex-col lg:flex"
      >
        <h2 className="text-xl mb-4">Opcije filtera</h2>

        {/* Categories */}
        <div className="mb-4">
          <h3 className="text-lg mb-2">Kategorije</h3>
          <ul className="space-y-2">
            <li
              className={`cursor-pointer ${!selectedCategory ? 'font-bold' : ''}`}
              onClick={() => handleCategoryChange('')}
            >
              Sve kategorije
            </li>
            {categories.map((category) => (
              <li
                key={category}
                className={`cursor-pointer ${selectedCategory === category ? 'font-bold' : ''}`}
                onClick={() => handleCategoryChange(category)}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>

        {/* Search */}
        <FormControl fullWidth variant="outlined" className="mb-4">
          <TextField label="Pretraga" value={searchQuery} onChange={handleSearchChange} />
        </FormControl>

        {/* Sort */}
        <FormControl fullWidth variant="outlined" className="mb-4">
          <InputLabel id="sort-label">Sortiraj po</InputLabel>
          <Select labelId="sort-label" value={sort} onChange={handleSortChange} label="Sort By">
            <MenuItem value="productId">ID</MenuItem>
            <MenuItem value="name">Nazivu</MenuItem>
            <MenuItem value="price">Cijeni</MenuItem>
          </Select>
        </FormControl>

        {/* Order */}
        <FormControl fullWidth variant="outlined" className="mb-4">
          <InputLabel id="order-label">Poredaj</InputLabel>
          <Select labelId="order-label" value={order} onChange={handleOrderChange} label="Order">
            <MenuItem value="ASC">Uzlazno</MenuItem>
            <MenuItem value="DESC">Silazno</MenuItem>
          </Select>
        </FormControl>

        {/* Price Range */}
        <div className="mb-4">
          <InputLabel>Raspon cijene</InputLabel>
          <Slider
            value={priceRange}
            onChange={handlePriceRangeChange}
            valueLabelDisplay="auto"
            min={0}
            max={1000}
            sx={{
                '& .MuiSlider-thumb': {
                  backgroundColor: '#a8834d', // Boja pokazivača
                  border: '2px solid #cea15a', // Boja granice pokazivača
                  '&:hover': {
                    backgroundColor: '#cea15a', // Boja pokazivača na hover
                  },
                },
                '& .MuiSlider-track': {
                  backgroundColor: '#a8834d',
                  border: 'none' // Boja traga (označava područje između min i max vrijednosti)
                },
                '& .MuiSlider-rail': {
                  backgroundColor: '#cea15a', // Boja vodilice (područje ispod traga)
                },
              }}
          />
        </div>

        <button className="flex items-center justify-center px-4 py-2 w-full text-xs font-bold tracking-[2px] border-[1px] border-black hover:bg-black text-black hover:text-white uppercase"
          onClick={fetchProducts}>
          Postavi filter
        </button>
      </div>

      {/* Product List */}
      <div className="w-full md:w-3/4 p-4">
        <h1 className="text-2xl mb-4">Artikli</h1>
        {products.length ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
            {products.map((product) => { 
                 const discountedPrice = calculateDiscountedPrice(product.price, product.discount?.amount || null);
            return (
              <div
                key={product.productId}
                className="group relative"
              >
                {/* Product Image */}
                <div className="relative overflow-hidden">
                {product.discount && (
                    <div className="absolute z-10 top-0 right-0 bg-red-500 text-white text-xs px-2 py-1 rounded-bl">
                      <div className='group-hover:animate-bounce'>{product.discount.amount}% popust</div>
                    </div>
                  )}
                  <img
                    className="w-full h-60 object-cover mb-4"
                    src={`${ApiConfig.API_URL}/uploads/products/${product.productId}/${product.imageUrl}`}
                    alt={product.name}
                  />
                  {/* Hover effect layer */}
                  <div className="absolute inset-0 bg-black opacity-0 transition-opacity group-hover:opacity-30"></div>
                </div>

                {/* Product Name */}
                <a
                  href={`${'/products/' + product.productId}`}
                  className="text-md font-semibold text-gray-900 transition-colors group-hover:text-customGold-500"
                >
                  {product.name}
                </a>

                {/* Product Rating */}
                <div className="mt-2">
                  <Rating
                    size='small'
                    name={`rating-${product.productId}`}
                    value={calculateAverageRating(product.reviews)}
                    precision={0.5}
                    readOnly
                  />
                </div>

                {/* Product Price */}
                <p className="text-sm text-gray-600">
                    {product.discount ? (
                        <div className='flex flex-row gap-2'>
                            <span className='text-gray-500 line-through'>{product.price}KM</span>
                            <span className='font-semibold text-red-500'>{Number(discountedPrice).toFixed(2)}KM</span> 
                        </div>
                        ) : (
                            <span>{Number(product.price).toFixed(2)}KM</span>
                        )}
                </p>

                {/* Add to Cart Button */}
                <a
                  className="mt-3 flex items-center justify-center px-4 py-2 w-full text-xs font-bold tracking-[2px] border-[1px] border-black hover:bg-black text-black hover:text-white uppercase"
                  onClick={() => handleAddToCart(product.productId)}
                >
                    <FaCartPlus className="mr-2 text-xl" />
                    {isSubmitting ? 'Dodavanje...' : 'Dodaj u korpu'}
                </a>

                
              </div>
            )})}
          </div>
        ) : (
          <p>Nema proizvoda za prikaz.</p>
        )}

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            sx={{
                '& .MuiPaginationItem-root': {
                  borderRadius: '50%', 
                  color: '#a8834d', 
                  border: '1px solid #a8834d', 
                },
                '& .MuiPaginationItem-previousNext': {
                  borderRadius: '50%', 
                  color: '#a8834d',
                  border: '1px solid #a8834d', 
                },
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: '#a8834d', 
                  color: '#fff', 
                  border: '1px solid #cea15a', 
                },
                '& .MuiPaginationItem-root:hover': {
                  backgroundColor: '#cea15a', 
                  color: '#fff', 
                  border: '1px solid #a8834d',
                },
              }}
          />
        </div>
      </div>
    </div>
  );
};

export default Shopping;
