import React, { useState, useEffect } from 'react';
import api from '../../API/api';
import EditAppointmentDialog from './EditAppointmentDialog';
import CreateAppointmentDialog from './CreateAppointmentDialog';
import Appointment from '../../types/appointment.type';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MdEdit } from 'react-icons/md';

// Stilizovanje pozadine na osnovu statusa
const getStatusStyle = (status: string | undefined) => {
  switch (status) {
    case 'pending':
      return { backgroundColor: '#FFF9E5' }; // svijetla žuta
    case 'confirmed':
      return { backgroundColor: '#E6F9E8' }; // svijetla zelena
    case 'canceled':
      return { backgroundColor: '#FDEDED' }; // svijetla crvena
    default:
      return {};
  }
};


const ResponsiveGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const AppointmentsList: React.FC = () => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<number | null>(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  useEffect(() => {
    fetchAppointments();
  }, [editDialogOpen, createDialogOpen]);

  const fetchAppointments = async () => {
    try {
      const response = await api('api/appointments', 'get', {});
      setAppointments(response.data);
    } catch (error) {
      console.error('Error fetching appointments', error);
    }
  };

  const handleAppointmentClick = (id: number) => {
    setSelectedAppointment(id);
    setEditDialogOpen(true);
  };

  const handleCreateAppointmentClick = () => {
    setCreateDialogOpen(true);
  };

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const todayAppointments = appointments.filter(app => app.date && new Date(app.date).toDateString() === today.toDateString());
  const tomorrowAppointments = appointments.filter(app => app.date && new Date(app.date).toDateString() === tomorrow.toDateString());
  const futureAppointments = appointments.filter(app => app.date && new Date(app.date) > tomorrow);

  const renderAppointmentTable = (appointments: Appointment[]) => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Vrijeme</TableCell>
            <TableCell>Servis</TableCell>
            <TableCell>Rezervirao</TableCell>
            <TableCell>Akcija</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appointments.length > 0 ? (
            appointments.map(app => (
              <TableRow
                key={app.appointmentsId}
                sx={getStatusStyle(app.status)}
              >
                <TableCell>{app.time ?? 'N/A'}</TableCell>
                <TableCell>{app.service?.name ?? 'N/A'}</TableCell>
                <TableCell>{app.user ? `${app.user.firstName} ${app.user.lastName}` : 'N/A'}</TableCell>
                <TableCell>
                  <Tooltip title="Pogledaj/Izmjeni">
                    <IconButton
                      onClick={() => handleAppointmentClick(app.appointmentsId ?? 0)}
                      color="primary"
                    >
                      <MdEdit />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>Nema termina</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      <Button
        onClick={handleCreateAppointmentClick}
        variant="contained"
        color="success"
        sx={{ marginBottom: 4 }}
      >
        Dodaj novi termin
      </Button>
      
      <ResponsiveGrid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Typography variant="h4" gutterBottom>Termini danas</Typography>
          {renderAppointmentTable(todayAppointments)}
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Typography variant="h4" gutterBottom>Termini sutra</Typography>
          {renderAppointmentTable(tomorrowAppointments)}
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Typography variant="h4" gutterBottom>Budući termini</Typography>
          {renderAppointmentTable(futureAppointments)}
        </Grid>
      </ResponsiveGrid>

      <EditAppointmentDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        appointmentId={selectedAppointment ?? 0}
      />

      <CreateAppointmentDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      />
    </div>
  );
};

export default AppointmentsList;
