import { Dialog, DialogTitle, DialogContent, Button, TextField, IconButton, MenuItem, Select } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import api, { ApiResponse } from '../../../API/api';

// Interface za propse
interface EditCategoryDialogProps {
  isOpen: boolean;
  onClose: () => void;
  categoryId: number;
  onCategoryUpdated: (category: any) => void;
}

// Interface za atribut kategorije
type Attribute = {
  name: string;
  type: string;

};

const EditCategoryDialog: React.FC<EditCategoryDialogProps> = ({ isOpen, onClose, categoryId, onCategoryUpdated }) => {
  const [categoryName, setCategoryName] = useState('');
  const [description, setDescription] = useState('');
  const [categoryAttributes, setCategoryAttributes] = useState<Attribute[]>([{ name: '', type: 'string' }]);

  // Dohvat kategorije i atributa kada se komponenta prikaže
  useEffect(() => {
    const fetchCategory = async () => {
      const response: ApiResponse = await api(`/api/product-categories/${categoryId}`, 'get', null, 'admin');
      if (response.status === 'ok') {
        const { name, description, categoryAttributes } = response.data;
        setCategoryName(name);
        setDescription(description);
        setCategoryAttributes(categoryAttributes.map((attr: any) => ({
          name: attr.name,
          type: attr.type,
          categoryAttributeId: attr.categoryAttributeId,
        })));
      }
    };
    if (isOpen) {
      fetchCategory();
    }
  }, [isOpen, categoryId]);

  // Handle input change za atribut
  const handleAttributeChange = (index: number, field: keyof Attribute, value: string) => {
    const newAttributes = [...categoryAttributes];
    newAttributes[index][field] = value;
    setCategoryAttributes(newAttributes);
  };

  // Dodavanje novog atributa
  const handleAddAttribute = () => {
    setCategoryAttributes([...categoryAttributes, { name: '', type: 'string' }]);
  };

  // Uklanjanje atributa
  const handleRemoveAttribute = (index: number) => {
    const newAttributes = categoryAttributes.filter((_, i) => i !== index);
    setCategoryAttributes(newAttributes);
  };

  // Submit forma za ažuriranje kategorije
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const response: ApiResponse = await api(`/api/product-categories/${categoryId}`, 'put', {
      name: categoryName,
      description,
      categoryAttributes,
    }, 'admin');

    if (response.status === 'ok') {
      onCategoryUpdated(response.data);
      setCategoryName('');
      setDescription('');
      setCategoryAttributes([{ name: '', type: 'string' }]);
    } else {
      alert('Failed to update category');
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Izmjena kategorije</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 mt-3">
            <TextField
              label="Naziv kategorije"
              variant="outlined"
              fullWidth
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <TextField
              label="Opis"
              variant="outlined"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>

          {/* Prikaz atributa */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Atributi</label>
            {categoryAttributes.map((attribute, index) => (
              <div key={index} className="flex items-center mb-2 space-x-2">
                <TextField
                  label="Naziv atributa"
                  variant="outlined"
                  value={attribute.name}
                  onChange={(e) => handleAttributeChange(index, 'name', e.target.value)}
                  className="w-1/2"
                  required
                />
                <Select
                  value={attribute.type}
                  onChange={(e) => handleAttributeChange(index, 'type', e.target.value)}
                  className="w-1/4"
                >
                  <MenuItem value="string">Text</MenuItem>
                  <MenuItem value="number">Broj</MenuItem>
                  <MenuItem value="boolean">Valid</MenuItem>
                </Select>
                <IconButton
                  color="error"
                  onClick={() => handleRemoveAttribute(index)}
                >
                  <FaMinus />
                </IconButton>
              </div>
            ))}
            <Button
              variant="outlined"
              onClick={handleAddAttribute}
              startIcon={<FaPlus />}
            >
              Novi atribut
            </Button>
          </div>

          <div className="flex justify-end space-x-4">
            <Button variant="outlined" onClick={onClose}>Izadji</Button>
            <Button type="submit" variant='outlined'>Snimi</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditCategoryDialog;
