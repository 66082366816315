import axios, { AxiosResponse } from "axios";
import { ApiConfig } from "../config/api.config";

// Definicija interfejsa za ApiResponse
export interface ApiResponse {
    status: 'ok' | 'error' | 'login' | 'forbidden';
    data: any;
}

export default function api(
    path: string,
    method: 'get' | 'post' | 'patch' | 'delete' | 'put',
    body: any | undefined,
    role: 'user' | 'admin' | 'guest' = 'guest',
    options: { useMultipartFormData?: boolean } = {},
) {
    return new Promise<ApiResponse>(async(resolve) => {
        const baseURL = await ApiConfig.API_URL;
        const requestData = {
            method: method,
            url: path,
            baseURL: baseURL,
            role: role,
            data: options.useMultipartFormData ? body : JSON.stringify(body),
            headers: {
                'Content-Type': options.useMultipartFormData ? 'multipart/form-data' : 'application/json',
                'Authorization': getToken(),
            },
        };

        axios(requestData)
            .then(res => responseHandler(res, resolve))
            .catch(async err => {
                if (err.response.status === 401) {
                    removeIdentity();
                    const response: ApiResponse = {
                        status: 'login',
                        data: null,
                    };

                    return resolve(response);
                }

                if (err.response.status === 403) {
                    const response: ApiResponse = {
                        status: 'forbidden',
                        data: err.response.data,
                    };
                    return resolve(response);
                }

                const response: ApiResponse = {
                    status: 'error',
                    data: err
                };

                resolve(response);
            });
    });
}

async function responseHandler(
    res: AxiosResponse<any>,
    resolve: (value: ApiResponse) => void,
) {
    if (res.status < 200 || res.status >= 300) {
        const response: ApiResponse = {
            status: 'error',
            data: res.data,
        };

        return resolve(response);
    }

    const response: ApiResponse = {
        status: 'ok',
        data: res.data,
    };

    return resolve(response);
}

function getToken(): string {
    const token = localStorage.getItem('api_token');
    return token ? 'Bearer ' + token : ''; // Return empty string if no token
}


export function saveToken(token: string) {
    localStorage.setItem('api_token', token);
}

export function removeIdentity() {
    localStorage.removeItem('api_token');
    localStorage.removeItem('api_identity_role');
    localStorage.removeItem('api_identity_id');
}

export async function checkAuth(): Promise<{ isAuthenticated: boolean, role?: string, userId?: number }> {
    const token = localStorage.getItem('api_token');
    if (!token) return { isAuthenticated: false };
    try {
        const baseURL = await ApiConfig.API_URL;
        const response = await axios.get(baseURL + '/auth/check', {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        return response.data;
    } catch (error) {
        return { isAuthenticated: false };
    }
}

