import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../API/AuthContext';
import api, { ApiResponse, saveToken } from '../API/api';
import { Alert } from '@mui/material';
import { messageType } from '../interfaces/message.interface';

// Logo Component
const Logo: React.FC = () => {
  return (
    <div className="flex items-center space-x-4 mb-3">
      <div className="flex items-center space-x-2 group">
        <div className="border-2 border-black pt-1 pb-1 pl-2 pr-2 text-center font-semibold group-hover:bg-black group-hover:text-white">
          N
        </div>
        <div className="flex flex-grow items-center">
          <div className="flex justify-between w-full">
            <span className="font-semibold tracking-wide p-1 pl-0">E</span>
            <span className="font-semibold tracking-wide p-1">J</span>
            <span className="font-semibold tracking-wide p-1">R</span>
            <span className="font-semibold tracking-wide p-1">A</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoginForm: React.FC = () => {
  const { setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState<messageType>();
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    api('/auth/login', 'post', { email, password }).then(async (res: ApiResponse) => {
      if (res.status === "error") {
        setMessage({message:"Sistemska greška.. Pokušajte ponovo!", variant:'error'});
        return;
      }

      if(res.status === 'ok') {
        if(res.data.statusCode !== undefined) {
          let message = ''

          switch (res.data.statusCode) {
            case -3001:
            case -3002:
              message = 'Korisničko ime ili lozinka nisu ispravni.'
              break;
          }
          setMessage({message: message, variant: 'error'});
          return;
        }

      if (res.status === 'ok') {
        saveToken(res.data.access_token);
        setIsAuthenticated(true);
        navigate('/');
      }
    }
    });
      
  };

  const handleRegisterClick = () => {
    navigate('/register'); 
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#f5f5f5] p-3">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md border border-[#e0e0e0]">
        <Logo />
        <h1 className="text-3xl font-bold mb-8 text-[#282828] text-center">Prijavi se</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="email" className="block text-[#473f33] text-sm font-medium mb-2">Email adresa</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border border-[#dcdcdc] rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#282828] transition duration-200 ease-in-out"
              placeholder="Vaša email adresa"
              required />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-[#473f33] text-sm font-medium mb-2">Lozinka</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border border-[#dcdcdc] rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#282828] transition duration-200 ease-in-out"
              placeholder="Vaša lozinka"
              required />
          </div>
          <button
            type="submit"
            className="w-full py-3 px-4 bg-[#282828] text-white font-semibold rounded-md shadow-md hover:bg-[#1f1f1f] focus:outline-none focus:ring-2 focus:ring-[#282828] transition duration-200 ease-in-out"
          >
            Prijavi se
          </button>
        </form>
        <p className="mt-4 text-center">
          Nema korisnički račun?{' '}
          <button onClick={handleRegisterClick} className="text-[#282828] font-semibold underline">
            Registrujte se!
          </button>
        </p>
        {message ? <Alert severity={message.variant} > {message.message} </Alert> : <div></div>}
      </div>
    </div>
  );
};

export default LoginForm;
