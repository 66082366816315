import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const ContactSection: React.FC = () => {
  return (
    <footer className="relative py-16 flex flex-col lg:flex-row min-h-screen bg-gray-800">
      {/* Mapa kao pozadina */}
      <div className="absolute inset-0">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d1128.7045119372435!2d18.203956672742816!3d44.02595257625476!3m2!1i1024!2i768!4f13.1!5e0!3m2!1shr!2sba!4v1726593397104!5m2!1shr!2sba"
          width="100%"
          height="100%"
          style={{ border: 0, position: 'absolute', top: 0, left: 0 }}
          allowFullScreen
          loading="lazy"
          title="Google Maps"
          className="z-[10]"
        ></iframe>
      </div>

      {/* Overlay sa sivom pozadinom i prozirnošću */}
      <div className="absolute inset-0 bg-gray-900 opacity-80 z-[11]"></div>

      {/* Kontakt podaci i obrazac unutar overlay sloja */}
      <div className="relative z-[12] flex flex-col lg:flex-row items-center justify-center lg:space-x-16 max-w-6xl mx-auto px-4">
        {/* Kontakt informacije */}
        <div className="lg:w-1/3 mb-8 lg:mb-0 text-white">
          <h2 className="text-4xl font-bold mb-6">Kontaktirajte nas</h2>
          <div className="space-y-4">
            <p className="flex items-center text-lg">
              <FaMapMarkerAlt className="text-yellow-500 mr-2" /> Gračanica bb, 71300 Visoko
            </p>
            <p className="flex items-center text-lg">
              <FaPhoneAlt className="text-yellow-500 mr-2" /> Telefon: (123) 456-7890
            </p>
            <p className="flex items-center text-lg">
              <FaEnvelope className="text-yellow-500 mr-2" /> 
              <a href="mailto:info@beautysalon.com" className="underline text-yellow-400">info@beautysalon.com</a>
            </p>
          </div>
        </div>

        {/* Obrazac za kontakt */}
        <div className="lg:w-2/3 bg-gray-700 p-8 rounded-lg shadow-lg text-white">
          <h3 className="text-2xl font-semibold mb-4">Pošaljite nam poruku</h3>
          <form action="#" method="POST" className="space-y-4">
            <div className="relative">
              <label htmlFor="name" className="block text-lg font-medium mb-2">Ime:</label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full px-4 py-2 bg-gray-600 text-white border border-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                required
              />
            </div>
            <div className="relative">
              <label htmlFor="email" className="block text-lg font-medium mb-2">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-4 py-2 bg-gray-600 text-white border border-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                required
              />
            </div>
            <div className="relative">
              <label htmlFor="message" className="block text-lg font-medium mb-2">Poruka:</label>
              <textarea
                id="message"
                name="message"
                rows={4}
                className="w-full px-4 py-2 bg-gray-600 text-white border border-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-yellow-500 text-black font-semibold py-2 px-6 rounded hover:bg-yellow-400 transition-colors"
            >
              Pošaljite
            </button>
          </form>
        </div>
      </div>
    </footer>
  );
};

export default ContactSection;
