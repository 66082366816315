import { Dialog, DialogTitle, DialogContent, Button, TextField, IconButton, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa'; 
import api, { ApiResponse } from '../../../API/api';

// U AddCategoryDialog.tsx
interface AddCategoryDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onCategoryAdded: (category: any) => void; 
}

type Attribute = {
  name: string;
  type: string;
};

const AddCategoryDialog: React.FC<AddCategoryDialogProps> = ({ isOpen, onClose, onCategoryAdded }) => {
  const [categoryName, setCategoryName] = useState('');
  const [description, setDescription] = useState('');
  const [categoryAttributes, setCategoryAttributes] = useState<Attribute[]>([{ name: '', type: 'string' }]);

  // Handle input change for attributes
  const handleAttributeChange = (index: number, field: keyof Attribute, value: string) => {
    const newAttributes = [...categoryAttributes];
    newAttributes[index][field] = value;
    setCategoryAttributes(newAttributes);
  };

  // Add a new attribute field
  const handleAddAttribute = () => {
    setCategoryAttributes([...categoryAttributes, { name: '', type: 'string' }]);
  };

  // Remove an attribute field
  const handleRemoveAttribute = (index: number) => {
    const newAttributes = categoryAttributes.filter((_, i) => i !== index);
    setCategoryAttributes(newAttributes);
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const response: ApiResponse = await api('/api/product-categories', 'post', { 
      name: categoryName, 
      description, 
      categoryAttributes: categoryAttributes 
    }, 'admin');
    
    if (response.status === 'ok') {
      onCategoryAdded(response.data);
      setCategoryName('');
      setDescription('');
      setCategoryAttributes([{ name: '', type: 'string' }]); // Reset attributes
    } else {
      alert('Greška prilikom dodavanja kategorije');
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Nova kategorija</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <TextField
              label="Naziv"
              variant="outlined"
              fullWidth
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <TextField
              label="Opis"
              variant="outlined"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>

          {/* Dynamic attributes section */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Atributi</label>
            {categoryAttributes.map((attribute, index) => (
              <div key={index} className="flex items-center mb-2 space-x-2">
                <TextField
                  label="Naziv atributa"
                  variant="outlined"
                  value={attribute.name}
                  onChange={(e) => handleAttributeChange(index, 'name', e.target.value)}
                  className="w-1/2"
                  required
                />
                <Select
                  value={attribute.type}
                  onChange={(e) => handleAttributeChange(index, 'type', e.target.value)}
                  className="w-1/4"
                >
                  <MenuItem value="string">Tekst</MenuItem>
                  <MenuItem value="number">Broj</MenuItem>
                  <MenuItem value="boolean">Opcija</MenuItem>
                </Select>
                <IconButton
                  color="error"
                  onClick={() => handleRemoveAttribute(index)}
                >
                  <FaMinus />
                </IconButton>
              </div>
            ))}
            <Button
              variant="outlined"
              color="primary"
              onClick={handleAddAttribute}
              startIcon={<FaPlus />}
            >
              Novi atribut
            </Button>
          </div>

          <div className="flex justify-end space-x-4">
            <Button
              variant="outlined"
              onClick={onClose}
            >
              Izađi
            </Button>
            <Button
              type="submit"
              variant="outlined"
            >
              Dodaj kategoriju
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddCategoryDialog;
