import React, { useEffect, useState } from "react";
import { BlogPost } from "../../../interfaces/blog-posts.interface";
import api, { ApiResponse } from "../../../API/api";
import { ApiConfig } from "../../../config/api.config";
import { useNavigate } from "react-router-dom";
import { FiMoreVertical } from "react-icons/fi";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Chip
} from "@mui/material";
import { MdDeleteOutline, MdOutlineEditNote } from "react-icons/md";

const BlogList: React.FC = () => {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSlug, setSelectedSlug] = useState<string | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    api("api/blog-posts", "get", undefined, "admin").then((res: ApiResponse) => {
      if (res.status === "ok") {
        setBlogPosts(res.data.data);
      }
    });
  }, []);

  const deleteBlogPost = (id: number) => {
    api(`api/blog-posts/${id}`, "delete", undefined, "admin").then((res: ApiResponse) => {
      if (res.status === "ok") {
        setBlogPosts(blogPosts.filter(post => post.blogPostsId !== id));
      }
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, slug: string) => {
    setAnchorEl(event.currentTarget);
    setSelectedSlug(slug);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedSlug(null);
  };

  const renderStatus = (status: string) => {
    let color: 'default' | 'primary' | 'secondary' | 'error' | 'success' | 'info' | 'warning' = 'default';
    switch (status) {
      case 'active':
        color = 'success';
        break;
      case 'inactive':
        color = 'default';
        break;
      case 'draft':
        color = 'info';
        break;
      case 'archived':
        color = 'warning';
        break;
      default:
        color = 'default';
        break;
    }
    return <Chip label={status} color={color} />;
  };

  return (
    <div className="p-4 lg:p-6 min-h-screen">
      <button
        className="bg-blue-500 text-white p-2 lg:p-3 rounded mb-4 lg:mb-6 shadow-md hover:bg-blue-600 transition"
        onClick={() => navigate('/admin/blogs/add')}
      >
        Dodaj Novi Blog
      </button>
      <TableContainer component={Paper} className="shadow-md">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Naslov</TableCell>
              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Slika</TableCell>
              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Kratak sadržaj</TableCell>
              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Status</TableCell>
              <TableCell>Akcije</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blogPosts.map((post, index) => (
              <TableRow key={post.blogPostsId}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{post.title}</TableCell>
                <TableCell className="justify-start-start" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                  <img
                    src={`${ApiConfig.API_URL}/uploads/blogs/${post.blogPostsId}/small-${post.imageUrl}`}
                    alt={post.title}
                    className="w-16 h-16 lg:w-20 lg:h-20 object-cover rounded-lg mx-auto shadow-sm"
                  />
                </TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{post.excerpt || post.content.slice(0, 100)}...</TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{renderStatus(post.status)}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={(e) => handleMenuOpen(e, post.slug)}
                  >
                    <FiMoreVertical />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: '20ch',
                      },
                    }}
                  >
                    <MenuItem onClick={() => {
                      navigate(`/admin/blogs/edit/${selectedSlug}`);
                      handleMenuClose();
                    }}>
                      <MdOutlineEditNote className="text-[23px]" /> Uredi
                    </MenuItem>
                    <MenuItem className="text-red-600" onClick={() => {
                      if (selectedSlug !== null) {
                        const postToDelete = blogPosts.find(post => post.slug === selectedSlug);
                        if (postToDelete) deleteBlogPost(postToDelete.blogPostsId);
                      }
                      handleMenuClose();
                    }}>
                      <MdDeleteOutline className="text-[20px] mr-1" /><span>Obriši</span>
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BlogList;
