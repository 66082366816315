import { Dialog, DialogContent, DialogTitle, MenuItem, Select, TextField, Button, InputLabel, FormControl, IconButton, useTheme, useMediaQuery } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api, { ApiResponse } from '../../../API/api';
import AddCategoryDialog from './AddCategoryDialog';
import EditCategoryDialog from './EditCategoryDialog';
import { FaPlus, FaEdit, FaImage } from 'react-icons/fa';
import { ApiConfig } from '../../../config/api.config';

interface AttributeValue {
  categoryAttributeId: number;
  value: string;
}

interface CategoryAttribute {
  categoryAttributeId: number;
  name: string;
  type: string;
}

interface Product {
  productId?: number;
  name: string;
  description: string;
  price: string;
  imageUrl?: string | null;
  quantity: string;
  productCategoryId: number;
  attributeValues?: AttributeValue[];
}

interface ShopFormProps {
  isOpen: boolean;
  onClose: () => void;
  product: Product | null;
  onProductUpdated: () => void;
}

const ShopForm: React.FC<ShopFormProps> = ({ isOpen, onClose, product, onProductUpdated }) => {
  const [formProduct, setFormProduct] = useState<Product>({
    name: '',
    description: '',
    price: '',
    quantity: '',
    productCategoryId: 0,
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [categories, setCategories] = useState<any[]>([]);
  const [isAddCategoryOpen, setIsAddCategoryOpen] = useState(false);
  const [isEditCategoryOpen, setIsEditCategoryOpen] = useState(false);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [productId, setProductId] = useState<number | undefined>(undefined);
  const [categoryAttributes, setCategoryAttributes] = useState<CategoryAttribute[]>([]);
  const [attributeValues, setAttributeValues] = useState<{ [key: number]: string }>({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  useEffect(() => {
    if (product) {
      setFormProduct(product);
      setCategoryId(product.productCategoryId);
      loadProductAttributes(product.productId);
      setProductId(product.productId);
    }
    fetchCategories();
  }, [product]);

  useEffect(() => {
    if (categoryId) fetchCategoryAttributes();
  }, [categoryId]);

  const fetchCategories = async () => {
    const response: ApiResponse = await api('/api/product-categories', 'get', 'admin');
    if (response.status === 'ok') setCategories(response.data);
  };

  const fetchCategoryAttributes = async () => {
    const response: ApiResponse = await api(`/api/category-attributes/${categoryId}`, 'get', 'admin');
    if (response.status === 'ok') setCategoryAttributes(response.data);
  };

  const loadProductAttributes = async (productId: number | undefined) => {
    if (!productId) return;
    const response: ApiResponse = await api(`/api/products/${productId}`, 'get', 'admin');
    if (response.status === 'ok') {
      const productData = response.data;
      const initialAttributeValues: { [key: number]: string } = {};
      productData.attributeValues?.forEach((attr: AttributeValue) => {
        initialAttributeValues[attr.categoryAttributeId] = attr.value;
      });
      setAttributeValues(initialAttributeValues);
    }
  };

  const handleAttributeChange = (categoryAttributeId: number, value: string) => {
    setAttributeValues({ ...attributeValues, [categoryAttributeId]: value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) setImageFile(e.target.files[0]);
  };

  const handleOnCloseDialog = () => {
    setFormProduct({
      name: '',
      description: '',
      price: '',
      quantity: '',
      productCategoryId: 0,
    });
    setImageFile(null);
    setCategoryId(null); // Ako želite resetirati kategoriju
    setProductId(undefined);
    setCategoryAttributes([]);
    setAttributeValues([]);
    onClose()
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const method = formProduct.productId ? 'put' : 'post';
    const url = formProduct.productId ? `/api/products/${formProduct.productId}` : '/api/products';
  
    const formData = new FormData();
  
    formData.append('name', formProduct.name);
    formData.append('description', formProduct.description);
    formData.append('price', formProduct.price);
    formData.append('quantity', formProduct.quantity.toString());
    formData.append('productCategoryId', formProduct.productCategoryId.toString());
  
    // Dodaj slike ako postoje
    if (imageFile) {
      formData.append('image', imageFile);
    }
  
    // Transformiši attributeValues u odgovarajući format
    const transformedAttributes = Object.entries(attributeValues).map(([key, value]) => ({
      categoryAttributeId: Number(key),
      value: value
    }));
  
    // Dodaj attributeValues kao JSON string
    formData.append('attributeValues', JSON.stringify(transformedAttributes));
  
    try {
      const response: ApiResponse = await api(url, method, formData, 'admin', { useMultipartFormData: true });
      if (response.status === 'ok') {
        onProductUpdated()
        handleOnCloseDialog();
        navigate('/admin/shop');
      } else {
        alert(`Greška prilikom snimanja artikla: ${response.data || 'Unknown error'}`);
      }
    } catch (error) {
      alert('Greška prilikom snimanja artikla.');
    }
  };
  
  const handleAddCategory = (newCategory: any) => {
    setCategories([...categories, newCategory]);
    setIsAddCategoryOpen(false);
  };

  const handleEditCategory = (newCategory: any) => {
    setCategories(categories.map(cat => cat.productCategoryId === newCategory.productCategoryId ? newCategory : cat));
    setIsEditCategoryOpen(false);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={(event, reason) => {
      // Sprečava zatvaranje dijaloga klikom izvan dijaloga
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} fullScreen={fullScreen} disableEscapeKeyDown>
      <DialogTitle>{formProduct.productId ? 'Izmjeni artikal' : 'Dodaj novi artikal'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 mt-3">
            <TextField
              label="Naziv"
              variant="outlined"
              fullWidth
              value={formProduct.name}
              onChange={(e) => setFormProduct({ ...formProduct, name: e.target.value })}
              required
            />
          </div>
          <div className="mb-4">
            <TextField
              label="Opis"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={formProduct.description}
              onChange={(e) => setFormProduct({ ...formProduct, description: e.target.value })}
              required
            />
          </div>
          <div className="mb-4">
            <TextField
              label="Cijena"
              variant="outlined"
              fullWidth
              value={formProduct.price}
              onChange={(e) => setFormProduct({ ...formProduct, price: e.target.value })}
              required
            />
          </div>
          <div className="mb-4">
            <TextField
              label="Stanje"
              variant="outlined"
              fullWidth
              value={formProduct.quantity}
              onChange={(e) => setFormProduct({ ...formProduct, quantity: e.target.value })}
              required
            />
          </div>
          <div className="mb-4">
            <FormControl fullWidth variant="outlined">
              <InputLabel>Kategorija</InputLabel>
              <Select
              className='mb-3'
                value={formProduct.productCategoryId}
                onChange={(e) => {
                  const selectedCategoryId = e.target.value as number;
                  setFormProduct({ ...formProduct, productCategoryId: selectedCategoryId });
                  setCategoryId(selectedCategoryId);
                }}
                label="Kategorija"
                required
              >
                {categories.map(category => (
                  <MenuItem key={category.productCategoryId} value={category.productCategoryId}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              <div className='flex gap-3'>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                className="mt-2"
                onClick={() => setIsAddCategoryOpen(true)}
                startIcon={<FaPlus />}
              >
                Nova kategorija
              </Button>
              {categoryId && (
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  className="mt-2 ml-2"
                  onClick={() => setIsEditCategoryOpen(true)}
                  startIcon={<FaEdit />}
                >
                  Izmjeni kategoriju
                </Button>
              )}
              </div>
            </FormControl>
          </div>
          <div className="mb-4">
            {categoryId && (<div className='mb-4'>Atributi</div>)}
            {categoryAttributes.map((attribute) => (
              <div key={attribute.categoryAttributeId} className="mb-4">
                <TextField
                  label={attribute.name}
                  variant="outlined"
                  fullWidth
                  type={attribute.type === 'number' ? 'number' : 'text'}
                  value={attributeValues[attribute.categoryAttributeId] || ''}
                  onChange={(e) => handleAttributeChange(attribute.categoryAttributeId, e.target.value)}
                />
              </div>
            ))}
          </div>
          <div className="mb-4">
          {imageFile ? (
              <img 
                src={URL.createObjectURL(imageFile)}  
                alt={formProduct.name} 
                className="max-w-60 object-cover rounded-lg mx-auto shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out mb-4" 
              />
            ) : (
              formProduct.imageUrl ? (
                <img 
                  src={ApiConfig.API_URL + "/uploads/products/" + formProduct.productId + "/" + formProduct.imageUrl} 
                  alt={formProduct.name} 
                  className="max-w-72 object-cover rounded-lg mx-auto shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out mb-4" 
                />
              ) : (
                <div></div>
              )
            )}


            <Button
              variant="outlined"
              component="label"
              fullWidth
              className="text-gray-700"
            >
              {formProduct.imageUrl ? (<div>Zamjeni sliku</div>) : (<div>Dodaj sliku</div>)}
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleFileChange}
              />
            </Button>
          </div>
          <div className="flex justify-end gap-2">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              className="mr-2"
            >
              Snimi
            </Button>
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={() => handleOnCloseDialog()}
            >
              Izađi
            </Button>
          </div>
        </form>
      </DialogContent>
      <AddCategoryDialog isOpen={isAddCategoryOpen} onClose={() => setIsAddCategoryOpen(false)} onCategoryAdded={handleAddCategory} />
      {categoryId !== null && (
        <EditCategoryDialog
          isOpen={isEditCategoryOpen}
          onClose={() => setIsEditCategoryOpen(false)}
          categoryId={categoryId}
          onCategoryUpdated={handleEditCategory}
        />
      )}
    </Dialog>
  );
};

export default ShopForm;
