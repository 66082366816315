import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../../API/api';
import { OrderItemType } from '../../types/order.item.type';

const OrderItemsList: React.FC = () => {
    const [orderItems, setOrderItems] = useState<OrderItemType[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrderItems = async () => {
            const response = await api('/api/order-items', 'get', undefined, 'admin');
            if (response.status === 'ok') {
                setOrderItems(response.data);
            }
        };

        fetchOrderItems();
    }, []);

    const handleEdit = (id: number) => {
        navigate(`/order-items/edit/${id}`);
    };

    const handleDelete = async (id: number) => {
        const response = await api(`/api/order-items/${id}`, 'delete', undefined, 'admin');
        if (response.status === 'ok') {
            setOrderItems(orderItems.filter(item => item.orderItemId !== id));
        }
    };

    const columns = [
        { field: 'orderId', headerName: 'ID', width: 90 },
        { field: 'productName', headerName: 'Product Name', width: 150 },
        { field: 'quantity', headerName: 'Quantity', width: 150 },
        { field: 'price', headerName: 'Price', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params: any) => (
                <>
                    <Button onClick={() => handleEdit(params.row.orderId)}>Edit {params.row.orderId}</Button>
                    <Button onClick={() => handleDelete(params.row.orderId)} color="error">Delete</Button>
                </>
            ),
        },
    ];

    return (
        <div className="p-4">
            <h1 className="text-xl mb-4">Order Items List</h1>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid rows={orderItems} columns={columns} pageSizeOptions={[5]} />
            </div>
        </div>
    );
};

export default OrderItemsList;
