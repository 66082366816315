import React from 'react';

const PackagesManagement: React.FC = () => {
  return (
    <div className="packages-management p-4">
      <h1 className="text-2xl font-semibold mb-4">Manage Packages</h1>
      {/* Dodajte funkcionalnosti za upravljanje paketima */}
    </div>
  );
};

export default PackagesManagement;
