import React from 'react';
import { Paper, Typography } from '@mui/material';

interface UserNotificationsProps {
  notifications: any[];
}

const UserNotifications: React.FC<UserNotificationsProps> = ({ notifications }) => {
  return (
    <div>
      <Typography variant="h6" className="mb-4">Notifikacije</Typography>
      {/* Implementirajte prikaz notifikacija */}
    </div>
  );
};

export default UserNotifications;
