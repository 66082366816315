import React, { useEffect, useState } from 'react';
import { useAuth } from '../../API/AuthContext';
import api, { ApiResponse } from '../../API/api';
import { AppointmentInterface } from '../../interfaces/appointments.inteface';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Popover, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate, formatTime } from '../../utils/date.utils';

interface AppointmentDialogProps {
  appointmentId: number;
  open: boolean;
  onClose: () => void;
}

const AppointmentDialog: React.FC<AppointmentDialogProps> = ({ appointmentId, open, onClose }) => {
  const [appointment, setAppointment] = useState<AppointmentInterface | null>(null);
  const { userId, isAuthenticated } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      fetchAppointment();
    }
  }, [appointmentId, open]);

  const fetchAppointment = async () => {
    try {
      const response: ApiResponse = await api(`/api/appointments/${appointmentId}`, 'get', null, 'user');
      if (response.status === 'ok') {
        setAppointment(response.data);
      } else {
        setError('Greška prilikom preuzimanja detalja o rezervaciji');
      }
    } catch (error) {
      setError('Greška prilikom preuzimanja detalja o rezervaciji');
      console.error(error);
    }
  };

  const handleReserve = async () => {
    setIsSubmitting(true);
    setError(null);

    try {
      const updateData = { userId };
      const response = await api(`api/appointments/${appointmentId}`, 'put', updateData);

      if(response.status === 'ok') {
        onClose()
      }

      if (response.status === 'error') {
        setError('Greška prilikom ažuriranja rezervacije');
      }
    } catch (error) {
      setError('Greška prilikom ažuriranja rezervacije');
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const openPopover = Boolean(!isAuthenticated);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ fontSize: '1.25rem' }}>
        Zahtjev za rezervaciju
      </DialogTitle>
      <DialogContent>
        {!isAuthenticated ? (<div></div>):(
          <Typography variant="body2" sx={{ mb: 2 }}>
          Molim Vas, nakon slanja zahtjeva za rezervaciju da istu kroz svoj profil u što kraćem roku potvrdite.{' '}
          <Link to="/profile" className="text-blue-500 hover:underline">Profil</Link>
        </Typography>
        )}
        {appointment ? (
          <div className="space-y-3">
            {/* Kartica za detalje rezervacije */}
            <div className="border border-gray-300 rounded-lg p-4">
              <Typography variant="h6" component="div" sx={{ fontSize: '1.25rem', fontWeight: 'bold', color: 'goldenrod', textTransform: 'uppercase', mb: 1 }}>
                {appointment.service.name}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
                {appointment.service.description}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.875rem', color: 'gray' }}>
                {formatDate(appointment.date)} u {formatTime(appointment.time)}
              </Typography>
            </div>
            {error && <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>{error}</Typography>}
          </div>
        ) : (
          <Typography color="textSecondary" sx={{ textAlign: 'center' }}>Učitavanje detalja rezervacije...</Typography>
        )}
      </DialogContent>
      <DialogActions>
      {!isAuthenticated ? (<div></div>) : (<Button onClick={handleReserve} color="primary" aria-owns={openPopover ? 'mouse-over-popover' : undefined} disabled={isSubmitting} sx={{ fontSize: '0.875rem' }}>
          {isSubmitting ? 'Rezervisanje...' : 'Rezerviši'}
        </Button>)}
        
        <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={openPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <Alert severity="warning">
        Samo registrovani korisnici mogu izvršiti rezervaciju.
      </Alert>
      </Popover>
        <Button onClick={onClose} color="secondary" sx={{ fontSize: '0.875rem' }}>
          Zatvori
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppointmentDialog;
