import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import api from '../../API/api';
import { Comment } from '../../interfaces/comments.interface';
import { ApiConfig } from '../../config/api.config';
import { useAuth } from '../../API/AuthContext';
import { Alert } from '@mui/material';
import moment from 'moment';

interface BlogCommentsProps {
  blogPostsId: number;
}

const BlogComments: React.FC<BlogCommentsProps> = ({ blogPostsId }) => {
  const { userId, isAuthenticated } = useAuth();
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState('');
  const [replyContent, setReplyContent] = useState<string | null>(null);
  const [replyToCommentId, setReplyToCommentId] = useState<number | null>(null);
  const [expandedReplies, setExpandedReplies] = useState<{ [key: number]: boolean }>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await api(`/api/comments/blog/${blogPostsId}`, 'get', {}, 'guest');
        if (response.status === 'ok') {
          setComments(response.data.filter((comment: { parentCommentId: null; }) => comment.parentCommentId === null));
        } else {
          console.error('Failed to fetch comments!');
        }
      } catch (error) {
        console.error('There was an error fetching the comments!', error);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [blogPostsId]);

  const handleCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewComment(event.target.value);
  };

  const handleReplyChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReplyContent(event.target.value);
  };

  const handleCommentSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!newComment.trim()) return;

    try {
      const response = await api('/api/comments', 'post', { postId: blogPostsId, content: newComment, userId }, 'user');
      if (response.status === 'ok') {
        setComments([...comments, response.data]);
        setNewComment('');
      } else {
        console.error('Greška prilikom dodavanja komentara!');
      }
    } catch (error) {
      console.error('Postoji problem prilikom dodavanja komentara, pokušaj ponovo kasnije.', error);
    }
  };

  const handleReplySubmit = async (commentId: number, event: FormEvent) => {
    event.preventDefault();
    if (!replyContent?.trim()) return;

    try {
      const response = await api('/api/comments/reply', 'post', { postId: blogPostsId, content: replyContent, userId, parentCommentId: commentId }, 'user');
      if (response.status === 'ok') {
        setComments(comments.map(comment => 
          comment.commentsId === commentId
            ? { ...comment, replies: [...comment.replies, response.data] }
            : comment
        ));
        setReplyContent('');
        setReplyToCommentId(null);
      } else {
        console.error('Greška prilikom dodavanja odgovora!');
      }
    } catch (error) {
      console.error('Postoji problem prilikom dodavanja odgovora, pokušaj ponovo kasnije.', error);
    }
  };

  const toggleReplies = (commentId: number) => {
    setExpandedReplies(prev => ({
      ...prev,
      [commentId]: !prev[commentId]
    }));
  };

  const renderReplies = (replies: Comment[]) => {
    return (
      <div className="mt-4 ml-6 border-l border-gray-300 pl-4">
        {replies.map(reply => (
          <div key={reply.commentsId} className="bg-gray-100 p-4 mb-4 rounded-lg border border-gray-200 shadow-sm">
            <div className="flex items-start space-x-4">
              <img
                src={ApiConfig.API_URL + '/uploads/users/' + reply.user.usersId + '/avatar-' + reply.user.profileImage}
                alt={`${reply.user.firstName} ${reply.user.lastName}`}
                className="h-10 w-10 rounded-full object-cover"
              />
              <div className="flex-1">
                <p className="font-medium text-gray-900">{reply.user.firstName} {reply.user.lastName}</p>
                <p className="text-gray-600 text-sm">{moment(reply.createdAt).format('MMMM D, YYYY - HH:mm')}</p>
                <p className="mt-2 text-gray-700">{reply.content}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (loading) return <div className="text-center py-8 text-gray-600">Učitavanje komentara...</div>;

  return (
    <section className="py-8">
      <h2 className="text-xl uppercase tracking-widest font-semibold mb-6 border-b pb-3 text-gray-800">Komentari</h2>
      <div className="space-y-6">
        {comments.map(comment => (
          <div key={comment.commentsId} className="bg-white p-4 mb-6 rounded-lg border border-gray-200 shadow-sm relative">
            <div className="flex items-start space-x-4">
              <img
                src={ApiConfig.API_URL + '/uploads/users/' + comment.user.usersId + '/avatar-' + comment.user.profileImage}
                alt={`${comment.user.firstName} ${comment.user.lastName}`}
                className="h-12 w-12 rounded-full object-cover"
              />
              <div className="flex-1">
                <p className="font-medium text-gray-900">{comment.user.firstName} {comment.user.lastName}</p>
                <p className="text-gray-600 text-sm">{moment(comment.createdAt).format('MMMM D, YYYY - HH:mm')}</p>
                <p className="mt-2 text-gray-700">{comment.content}</p>
                {isAuthenticated && (
                  <button 
                    onClick={() => setReplyToCommentId(comment.commentsId)}
                    className="absolute top-2 right-2 text-customGold-600 hover:underline text-sm uppercase tracking-widest"
                  >
                    Odgovori
                  </button>
                )}
              </div>
            </div>
            {isAuthenticated && replyToCommentId === comment.commentsId && (
              <form onSubmit={(e) => handleReplySubmit(comment.commentsId, e)} className="mt-4 bg-gray-100 p-4 rounded-lg border border-gray-300 shadow-inner">
                <textarea
                  value={replyContent || ''}
                  onChange={handleReplyChange}
                  rows={4}
                  className="w-full p-2 border border-gray-300 rounded-md text-sm"
                  placeholder="Napišite odgovor..."
                />
                <button
                  type="submit"
                  className="mt-2 px-4 py-2 bg-black text-white hover:bg-customGold-800 uppercase text-xs tracking-widest"
                >
                  Dodaj odgovor
                </button>
              </form>
            )}
            {comment.replies && comment.replies.length > 0 && (
              <div>
                {!expandedReplies[comment.commentsId] ? (
                  <button
                    onClick={() => toggleReplies(comment.commentsId)}
                    className="mt-2 hover:underline text-sm text-customGold-600"
                  >
                    {`Prikaži odgovore (${comment.replies.length})`}
                  </button>
                ) : (
                  <>
                    {renderReplies(comment.replies)}
                    <button
                      onClick={() => toggleReplies(comment.commentsId)}
                      className="mt-2 hover:underline text-sm text-customGold-600"
                    >
                      Sakrij odgovore
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      {isAuthenticated ? (
        <form onSubmit={handleCommentSubmit} className="mt-6 bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
          <textarea
            value={newComment}
            onChange={handleCommentChange}
            rows={4}
            className="w-full p-2 border border-gray-300 rounded-md text-sm"
            placeholder="Napišite komentar..."
          />
          <button
            type="submit"
            className="mt-2 px-4 py-2 bg-black text-white hover:bg-customGold-800 uppercase text-xs tracking-widest"
          >
            Dodaj komentar
          </button>
        </form>
      ) : (
        <Alert severity="info" className="mt-6">
          Morate biti prijavljeni za ostavljanje komentara.
        </Alert>
      )}
    </section>
  );
};

export default BlogComments;
