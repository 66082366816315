import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  Button,
  Switch,
} from '@mui/material';
import api, { ApiResponse } from '../../API/api';

interface FieldData {
  value: string | boolean | number;
  type: 'text' | 'textarea' | 'boolean' | 'date';
  [key: string]: any;
}

interface SectionData {
  [key: string]: FieldData | SectionData | FieldData[];
}

const SectionAdmin: React.FC = () => {
  const [sections, setSections] = useState<{ [key: string]: SectionData }>({});
  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response: ApiResponse = await api('/api/json-update', 'get', undefined, 'admin');
        if (response.status === 'ok') {
          setSections(response.data);
        } else {
          console.error('Failed to fetch sections.');
        }
      } catch (error) {
        console.error('There was an error fetching the sections!', error);
      }
    };

    fetchSections();
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleFieldChange = (section: string, field: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    const sectionData = sections[section] as SectionData;

    setSections({
      ...sections,
      [section]: {
        ...sectionData,
        [field]: {
          ...(sectionData[field] as FieldData),
          value,
        },
      },
    });
  };

  const handleObjectFieldChange = (
    section: string,
    field: string,
    index: number,
    subfield: string
  ) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<{ checked: boolean }>) => {
    const value = (event.target as HTMLInputElement).type === 'checkbox'
      ? (event.target as HTMLInputElement).checked
      : (event.target as HTMLInputElement).value;

    const sectionData = sections[section] as SectionData;
    const fieldDataArray = sectionData[field] as FieldData[];

    setSections({
      ...sections,
      [section]: {
        ...sectionData,
        [field]: fieldDataArray.map((item, i) =>
          i === index ? { ...item, [subfield]: { ...item[subfield], value } } : item
        ),
      },
    });
  };

  const handleSubmit = async (section: string) => {
    try {
      const response: ApiResponse = await api(`/api/json-update/${section}`, 'put', sections[section], 'admin');
      if (response.status === 'ok') {
        alert('Section updated successfully!');
      } else {
        alert('Failed to update section.');
      }
    } catch (error) {
      console.error('There was an error updating the section!', error);
    }
  };

  const renderField = (section: string, field: string, value: any) => {
    if (Array.isArray(value)) {
      return value.map((item: any, index: number) => (
        <div key={`${field}-${index}`} className="mb-6 flex flex-col gap-2">
          {Object.keys(item).map((subfield) => (
            typeof item[subfield].value === 'boolean' ? (
              <div key={subfield} className="flex items-center mb-4 space-x-4">
                <Typography variant="body1">{subfield}</Typography>
                <Switch
                  checked={item[subfield].value}
                  onChange={(e) =>
                    handleObjectFieldChange(section, field, index, subfield)(
                      e as unknown as React.ChangeEvent<{ checked: boolean }>
                    )
                  }
                  name={subfield}
                />
              </div>
            ) : (
              <TextField
                key={subfield}
                label={subfield}
                name={subfield}
                value={item[subfield].value || ''}
                onChange={(e) =>
                  handleObjectFieldChange(section, field, index, subfield)(
                    e as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  )
                }
                multiline={item[subfield].type === 'textarea'}
                fullWidth
                variant="outlined"
                className="mb-4"
                type={item[subfield].type === 'date' ? 'date' : 'text'}
              />
            )
          ))}
        </div>
      ));
    } else if (typeof value === 'object' && value !== null) {
      return (
        <div key={field} className="mb-6">
          {Object.keys(value).map((subfield) => (
            <TextField
              key={subfield}
              label={subfield}
              name={subfield}
              value={value[subfield]?.value || ''}
              onChange={handleFieldChange(section, subfield)}
              multiline={value[subfield]?.type === 'textarea'}
              fullWidth
              variant="outlined"
              className="mb-4"
              type={value[subfield]?.type === 'date' ? 'date' : 'text'}
            />
          ))}
        </div>
      );
    } else {
      return (
        <TextField
          key={field}
          label={field}
          name={field}
          value={value || ''}
          onChange={handleFieldChange(section, field)}
          multiline={typeof value === 'string' && value.includes('-')}
          fullWidth
          variant="outlined"
          className="mb-4"
          type={typeof value === 'string' && value.includes('-') ? 'date' : 'text'}
        />
      );
    }
  };

  return (
    <div className="p-6  min-h-screen">
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="Sections Tabs"
        className="mb-6"
        variant="scrollable"
        scrollButtons="auto"
      >
        {Object.keys(sections).map((section) => (
          <Tab key={section} label={section} />
        ))}
      </Tabs>
      <Box p={1}>
        {Object.keys(sections).map((section, index) => (
          selectedTab === index && (
            <form key={section} onSubmit={(e) => { e.preventDefault(); handleSubmit(section); }}>
              {Object.keys(sections[section]).map((field) => {
                const value = sections[section][field];
                return (
                  <div key={field} className="mb-6">
                    <Typography variant="h5" gutterBottom>
                      {field}
                    </Typography>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {renderField(section, field, value)}
                    </div>
                  </div>
                );
              })}
              <Button type="submit" variant="contained" color="primary" className="mt-4">
                Izmjeni {section}
              </Button>
            </form>
          )
        ))}
      </Box>
    </div>
  );
}

export default SectionAdmin;
