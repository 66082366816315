import React from 'react';

const UsersManagement: React.FC = () => {
  return (
    <div className="users-management p-4">
      <h1 className="text-2xl font-semibold mb-4">Manage Users</h1>
      {/* Dodajte funkcionalnosti za upravljanje korisnicima */}
    </div>
  );
};

export default UsersManagement;
