import React, { useEffect, useState } from 'react';
import api, { ApiResponse } from '../../../API/api';
import { ApiConfig } from '../../../config/api.config';

interface Product {
  productId: number;
  name: string;
  description: string | null;
  price: string;
  imageUrl: string | null;
  discount: {
    amount: string;
    type: string;
  } | null;
}

const RandomDiscountedProduct: React.FC = () => {
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    const fetchRandomDiscountedProduct = async () => {
      try {
        const response: ApiResponse = await api('/api/products/random-discounted', 'get', undefined, 'guest');
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching random discounted product:', error);
      }
    };

    fetchRandomDiscountedProduct();
  }, []);

  if (!product) {
    return <p>Loading...</p>;
  }
  
  const calculatePriceWithoutTax = (price: number) => {
    const priceWithoutDiscountAndTax = price / (1 + 0.17);
    return priceWithoutDiscountAndTax;
  }

  const originalPrice = calculatePriceWithoutTax(parseFloat(product.price)) ;
  const discountAmount = product.discount ? parseFloat(product.discount.amount) : 0;
  const priceWithDiscount = originalPrice - (originalPrice * (discountAmount / 100));
  const priceWithDiscountAndVAT = priceWithDiscount * (1 + 0.17);
  

  return (
    <section className="container mx-auto items-center p-4 py-16 bg-white text-black text-center flex flex-col min-h-[70vh] ">
    
      <div className="relative flex flex-col max-w-screen-lg md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
        {product.imageUrl && (
          <div className="md:w-1/2 w-full flex justify-center relative">
            <img
              src={
                ApiConfig.API_URL + "/uploads/products/" + product.productId + "/" + product.imageUrl
              }
              alt={product.name}
              className="w-full md:w-2/3 h-48 md:h-auto object-contain rounded-md"
            />
    
            {/* Badge za popust preko slike */}
            {product.discount && (
              <div className="absolute top-0 right-0 bg-red-500 text-white px-3 py-1 rounded-bl-md animate-bounce">
                {product.discount.amount}% popust
              </div>
            )}
          </div>
        )}
        <div className="md:w-1/2 w-full flex flex-col items-center lg:items-start space-y-4">
          <h3 className="text-2xl font-semibold">{product.name}</h3>
          <p className="text-gray-600 lg:text-left">
            {product.description!.length > 100 
              ? `${product.description!.slice(0, 200)}...` 
              : product.description}
          </p>
          <div className="flex items-center justify-center">
            {product.discount && (
              <span className=" text-gray-300 line-through px-2 py-1 rounded-md text-5xl font-bold ">
                {`${product.price}`}
              </span>
            )}
             <p className="text-2xl font-bold">
              {`${priceWithDiscountAndVAT.toFixed(2)} KM`}
            </p>
           
          </div>
          <a
            href={`/products/${product.productId}`}
           className="px-10 py-2 w-full text-xs font-bold tracking-[2px] border-[1px] border-black hover:bg-black text-black hover:text-white uppercase"
          >
            saznaj više
          </a>
        </div>
      </div>
    </section>
  );
};

export default RandomDiscountedProduct;
