import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../API/api';
import { BlogPost } from '../../interfaces/blog-posts.interface';
import { ApiConfig } from '../../config/api.config';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const BlogPostsByCategory: React.FC = () => {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { categoryName } = useParams<{ categoryName: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (categoryName) {
      api(`/api/blog-posts/category/${categoryName}?page=${page}`, 'get', {}, 'guest')
        .then((response: { status: string; data: { data: BlogPost[]; pages: number } }) => {
          if (response.status === 'ok') {
            setBlogPosts(response.data.data);
            setTotalPages(response.data.pages);
          } else {
            console.error('Failed to fetch blog posts!');
          }
        })
        .catch((error: any) => {
          console.error('There was an error fetching the blog posts!', error);
        });
    }
  }, [categoryName, page]);

  const handleReadMore = (slug: string) => {
    navigate(`/blog/${slug}`);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <div className="mx-auto">
      <section className="py-16 bg-gray-50">
        <h2 className="text-4xl font-bold mb-8 text-center uppercase text-gray-800 tracking-wide">
          Blogovi u kategoriji: {categoryName}
        </h2>
      </section>
      <section className="pb-16 lg:px-44 px-4 py-6 sm:px-6 sm:py-8">
        <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-3">
          {blogPosts.map(post => (
            <div
              key={post.blogPostsId}
              className="group overflow-hidden rounded-lg shadow-lg bg-white transform hover:-translate-y-2 transition-transform duration-200 "
            >
              <img
                src={ApiConfig.API_URL + "/uploads/blogs/" + post.blogPostsId + "/small-" + post.imageUrl}
                alt={post.title}
                className="w-full h-72 object-cover object-center transition-transform duration-200 group-hover:scale-105 rounded-t-lg"
              />
              <div className="p-6 text-xs">
                <p className="text-gray-700 text-inherit mb-3 uppercase tracking-wider">
                  {new Intl.DateTimeFormat('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  }).format(new Date(post.createdAt))}
                </p>
                <p className="uppercase font-semibold tracking-wider text-[15px] mb-3">{post.title}</p>
                <p className="text-gray-800 text-sm mb-6">{post.excerpt.slice(0, 100)}...</p>
                <button
                  className="relative text-customGold-600 uppercase font-semibold tracking-[3px] overflow-hidden group"
                  onClick={() => handleReadMore(post.slug)}
                >
                  Čitaj više
                  <span className="absolute left-0 bottom-0 w-full h-px bg-customGold-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-out"></span>
                </button>
              </div>
            </div>
          ))}
        </div>
        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" className="mt-8">
          <Pagination 
            count={totalPages} 
            page={page} 
            onChange={handlePageChange} 
            variant="outlined" 
            shape="rounded" 
            color="primary"
            sx={{
              '& .MuiPaginationItem-root': {
                borderRadius: '50%', 
                color: '#a8834d', 
                border: '1px solid #a8834d', 
              },
              '& .MuiPaginationItem-previousNext': {
                borderRadius: '50%', 
                color: '#a8834d',
                border: '1px solid #a8834d', 
              },
              '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: '#a8834d', 
                color: '#fff', 
                border: '1px solid #cea15a', 
              },
              '& .MuiPaginationItem-root:hover': {
                backgroundColor: '#cea15a', 
                color: '#fff', 
                border: '1px solid #a8834d',
              },
            }}
          />
        </Stack>
      </section>
    </div>
  );
};

export default BlogPostsByCategory;
