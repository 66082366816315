import React, { useState, useEffect } from "react";
import api, { ApiResponse } from "../../../API/api";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../API/AuthContext";
import QuillEditor from "./QuillEditor";
import { ApiConfig } from "../../../config/api.config";

const EditBlogPost: React.FC = () => {
  const { slugParam } = useParams<{ slugParam: string }>();
  const [blogPostId, setBlogPostId] = useState<number | null>(null);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [categories, setCategories] = useState<{ categoriesId: number; name: string }[]>([]);
  const [excerpt, setExcerpt] = useState("");
  const [authorComment, setAuthorComment] = useState("");
  const [tags, setTags] = useState("");
  const [status, setStatus] = useState("draft");
  const [slug, setSlug] = useState("");
  const [existingImage, setExistingImage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { userId } = useAuth(); // Pretpostavljam da useAuth vraća { userId }

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res: ApiResponse = await api("api/categories", "get", undefined, "admin");
        if (res.status === "ok") {
          setCategories(res.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchBlogPost = async () => {
      try {
        const res: ApiResponse = await api(`api/blog-posts/${slugParam}`, "get", undefined, "admin");
        if (res.status === "ok") {
          setBlogPostId(res.data.blogPost.blogPostsId);
          setTitle(res.data.blogPost.title);
          setContent(res.data.blogPost.content);
          setExistingImage(res.data.blogPost.imageUrl);
          setCategoryId(res.data.blogPost.categoryId);
          setExcerpt(res.data.blogPost.excerpt);
          setAuthorComment(res.data.blogPost.authorComment);
          setTags(res.data.blogPost.tags);
          setStatus(res.data.blogPost.status);
          setSlug(res.data.blogPost.slug);
        }
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    fetchCategories();
    fetchBlogPost();
  }, [slugParam]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    if (image) formData.append("image", image);
    if (categoryId !== null) formData.append("categoryId", categoryId.toString());
    formData.append("excerpt", excerpt);
    formData.append("authorComment", authorComment);
    formData.append("tags", tags);
    formData.append("status", status);
    formData.append("slug", slug);
    if (userId !== null) formData.append("authorId", userId.toString());

    try {
      const res: ApiResponse = await api(`api/blog-posts/${slugParam}`, "put", formData, "admin", { useMultipartFormData: true });
      if (res.status === "ok") {
        navigate("/admin/blogs");
      } else {
        console.error("Error updating blog post:", res.status);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4 lg:p-8 mx-auto max-w-4xl">
      <h1 className="text-2xl lg:text-3xl font-bold mb-6">Izmijeni Blog Post</h1>
      <form onSubmit={handleSubmit} className="space-y-6 lg:space-y-8">
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Naslov</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Naslov"
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Sadržaj</label>
          <QuillEditor value={content} onChange={setContent} />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Kategorija (opcionalno)</label>
          <select
            value={categoryId !== null ? categoryId : ""}
            onChange={(e) => setCategoryId(e.target.value ? parseInt(e.target.value, 10) : null)}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Izaberite kategoriju</option>
            {categories.map((category) => (
              <option key={category.categoriesId} value={category.categoriesId}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Kratak sadržaj (opcionalno)</label>
          <textarea
            value={excerpt}
            onChange={(e) => setExcerpt(e.target.value)}
            placeholder="Kratak sadržaj"
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            rows={4}
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Komentar autora (opcionalno)</label>
          <textarea
            value={authorComment}
            onChange={(e) => setAuthorComment(e.target.value)}
            placeholder="Komentar autora"
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            rows={4}
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Tagovi (opcionalno)</label>
          <input
            type="text"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            placeholder="Tagovi"
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Status</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="draft">Nacrt</option>
            <option value="published">Objavljeno</option>
            <option value="archived">Arhivirano</option>
          </select>
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Slug</label>
          <input
            type="text"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            placeholder="Slug za URL"
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">Slika (opcionalno)</label>
          {existingImage && (
            <img src={ApiConfig.API_URL + '/uploads/blogs/' + blogPostId + '/' + existingImage} alt="Existing" className="w-full h-auto mb-4 rounded-lg shadow-sm" />
          )}
          <input
            type="file"
            onChange={(e) => setImage(e.target.files ? e.target.files[0] : null)}
            className="w-full p-2 border border-gray-300 rounded-lg shadow-sm"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white p-3 rounded-lg shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          disabled={loading}
        >
          {loading ? "Ažuriranje..." : "Ažuriraj Blog Post"}
        </button>
      </form>
    </div>
  );
};

export default EditBlogPost;
