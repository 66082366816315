import React, { useState, useEffect } from "react";
import api, { ApiResponse } from "../../../API/api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../API/AuthContext";
import QuillEditor from "./QuillEditor";
import {
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  CircularProgress,
  Typography,
  Box,
  Grid
} from "@mui/material";

const AddBlogPost: React.FC = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [categories, setCategories] = useState<{ categoriesId: number; name: string }[]>([]);
  const [excerpt, setExcerpt] = useState("");
  const [tags, setTags] = useState("");
  const [status, setStatus] = useState("draft");
  const [slug, setSlug] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { userId } = useAuth(); // Pretpostavljam da useAuth vraća { userId }

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res: ApiResponse = await api("api/categories", "get", undefined, "admin");
        if (res.status === "ok") {
          setCategories(res.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    if (image) formData.append("image", image);
    if (categoryId !== null) formData.append("categoryId", categoryId.toString());
    formData.append("excerpt", excerpt);
    formData.append("tags", tags);
    formData.append("status", status);
    formData.append("slug", slug);
    if (userId !== null) formData.append("authorId", userId.toString());

    try {
      const res: ApiResponse = await api("api/blog-posts", "post", formData, "admin", { useMultipartFormData: true });
      if (res.status === "ok") {
        navigate("/admin/blogs");
      } else {
        console.error("Error adding blog post:", res.status);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 4, maxWidth: 'lg', mx: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Dodaj Novi Blog Post
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Naslov"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Naslov"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} className="pb-16 lg:pb-10">
            <QuillEditor value={content} onChange={setContent} />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="Kratak sadržaj (opcionalno)"
              value={excerpt}
              onChange={(e) => setExcerpt(e.target.value)}
              placeholder="Kratak sadržaj"
              multiline
              rows={3}
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="Tagovi (opcionalno)"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              placeholder="Tagovi"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Kategorija (opcionalno)</InputLabel>
              <Select
                value={categoryId !== null ? categoryId.toString() : ""}
                onChange={(e) => setCategoryId(e.target.value ? parseInt(e.target.value, 10) : null)}
              >
                <MenuItem value="">Izaberite kategoriju</MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.categoriesId} value={category.categoriesId.toString()}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Izaberite kategoriju za blog post</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="draft">Nacrt</MenuItem>
                <MenuItem value="published">Objavljeno</MenuItem>
                <MenuItem value="archived">Arhivirano</MenuItem>
              </Select>
              <FormHelperText>Izaberite status blog posta</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Slug"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              placeholder="Slug za URL"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              fullWidth
            >
              Odaberite sliku
              <input
                type="file"
                hidden
                onChange={(e) => {
                  const input = e.target as HTMLInputElement;
                  setImage(input.files ? input.files[0] : null);
                }}
              />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
              startIcon={loading ? <CircularProgress size={24} /> : null}
            >
              {loading ? "Dodavanje..." : "Dodaj Blog Post"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddBlogPost;
