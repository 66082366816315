import React, { useState } from 'react';
import { Paper, Typography, Box, Link, Pagination } from '@mui/material';
import { Comment } from '../../../interfaces/comments.interface';

interface UserCommentsProps {
  comments: Comment[];
}

const ITEMS_PER_PAGE = 5;

// Funkcija za prikaz odgovora na komentare
const CommentItem: React.FC<{ comment: Comment }> = ({ comment }) => {
  return (
    <Box sx={{ marginBottom: 2 }}>
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography >
          <Link href={`/blog/${comment.post.slug}`} underline="hover">
            {comment.post.title}
          </Link>
        </Typography>
        <Typography variant="body1">{comment.content}</Typography>
        <Typography variant="body2" color="textSecondary">
          {new Date(comment.createdAt).toLocaleDateString()} - {new Date(comment.createdAt).toLocaleTimeString()}
        </Typography>
      </Paper>

      {/* Prikaz odgovora (ako ih ima) */}
      {comment.replies?.length > 0 && (
        <Box sx={{ marginLeft: 4, marginTop: 1 }}>
          {comment.replies.map((reply) => (
            <Box key={reply.commentsId} sx={{ marginBottom: 1 }}>
              <Paper elevation={2} sx={{ padding: 2 }}>
              <Typography variant="body2">
              <strong>{reply.user.firstName} {reply.user.lastName}</strong>
                </Typography>
                <Typography variant="body2">
                   {reply.content}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                 {new Date(reply.createdAt).toLocaleDateString()} - {new Date(reply.createdAt).toLocaleTimeString()}
                </Typography>
              </Paper>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

const UserComments: React.FC<UserCommentsProps> = ({ comments }) => {
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(comments.length / ITEMS_PER_PAGE);

  // Komentari za prikaz na tekućoj stranici
  const displayedComments = comments.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <div>
      {displayedComments.length > 0 ? (
        displayedComments.map((comment) => (
          <CommentItem key={comment.commentsId} comment={comment} />
        ))
      ) : (
        <Typography variant="body1" color="textSecondary">Nema komentara.</Typography>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </div>
  );
};

export default UserComments;
