import React, { useEffect, useState } from 'react';
import api, { ApiResponse } from '../../API/api';
import { CircularProgress } from '@mui/material';

// Definicija Skeleton Loader-a
const SkeletonLoader: React.FC = () => (
  <div className="bg-white shadow-lg rounded-lg p-6 lg:w-[350px] space-y-4">
    <div className="h-8 bg-gray-300 animate-pulse rounded mb-4"></div>
    <div className="space-y-4">
      <div className="h-4 bg-gray-300 animate-pulse rounded w-3/4 mb-2"></div>
      <div className="h-4 bg-gray-300 animate-pulse rounded w-2/3 mb-2"></div>
      <div className="h-4 bg-gray-300 animate-pulse rounded w-1/2 mb-2"></div>
    </div>
    <div className="mt-6">
      <div className="h-4 bg-gray-300 animate-pulse rounded mb-2"></div>
      <div className="h-4 bg-gray-300 animate-pulse rounded mb-2"></div>
    </div>
  </div>
);

interface FieldData {
  value: any;
  type: 'text' | 'time' | 'date';
}

interface WorkingDay {
  Dan: FieldData;
  Vrijeme: FieldData;
  Status: FieldData;
}

interface Holiday {
  Naziv: FieldData;
  Datum_od: FieldData;
  Datum_do: FieldData;
  Poruka: FieldData;
}

interface Section3Data {
  Naziv: FieldData;
  Radni_dani: WorkingDay[];
  Praznici: Holiday[];
}

const Section3: React.FC = () => {
  const [data, setData] = useState<Section3Data | null>(null);

  useEffect(() => {
    api('/api/json-update/section-3', 'get', {}, 'guest')
      .then((response: ApiResponse) => {
        if (response.status === 'ok') {
          setData(response.data);
        } else {
          console.error('Failed to fetch section data');
        }
      })
      .catch((error: any) => {
        console.error('There was an error fetching the section data!', error);
      });
  }, []);

  if (!data) return <SkeletonLoader />;

  const { Radni_dani, Praznici } = data;
  const allDaysOpen = Radni_dani.every(day => day.Status.value === true);
  const allDaysSameTime = Radni_dani.every(day => day.Vrijeme.value === Radni_dani[0].Vrijeme.value);

  const workingDaysContent = () => {
    if (allDaysOpen && allDaysSameTime) {
      return <span>Radni dani - {Radni_dani[0].Vrijeme.value}</span>;
    }

    return (
      <div className="space-y-4">
        {Radni_dani.map((day, index) => (
          <div key={index} className="flex justify-between">
            <span className="font-medium">{day.Dan.value}:</span>
            <span
              className={`font-medium ${
                !day.Status.value ? 'text-red-800' : ''
              }`}
            >{day.Status.value ? day.Vrijeme.value : 'Ne radimo'}</span>
          </div>
        ))}
      </div>
    );
  };

  const now = new Date();
  const sevenDaysFromNow = new Date(now);
  sevenDaysFromNow.setDate(now.getDate() + 7);

  const holidayMessage = Praznici.find(holiday => {
    const startDate = new Date(holiday.Datum_od.value);
    const endDate = new Date(holiday.Datum_do.value);

    return (now <= endDate && (startDate <= sevenDaysFromNow || now >= startDate));
  });

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 lg:w-[350px]">
      <h3 className="text-2xl font-semibold mb-4">{data.Naziv.value}</h3>
      <div className="space-y-4">
        {workingDaysContent()}
      </div>
      {holidayMessage && (
        <div className="mt-6 bg-[#f8f5f0] p-4 rounded-lg border border-[#c59d5f] shadow-sm">
          <h4 className="text-xl font-semibold text-[#c59d5f] mb-2">{holidayMessage.Naziv.value}</h4>
          <p className="text-[#6d4c41] mb-2">{holidayMessage.Poruka.value}</p>
          <p className="text-[#a87f6f] text-sm italic">
            {new Date(holidayMessage.Datum_od.value).toLocaleDateString()} - {new Date(holidayMessage.Datum_do.value).toLocaleDateString()}
          </p>
        </div>
      )}
    </div>
  );
};

export default Section3;
