// src/components/Sidebar.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaComments, FaImages, FaBoxes, FaUsers, FaRegCalendarAlt, FaStar, FaBlog, FaBars,FaShoppingBasket, FaBox } from 'react-icons/fa';
import { LiaThemeco } from "react-icons/lia";
import { RiDashboard2Line } from "react-icons/ri";
import { Fab, Menu, MenuItem, useMediaQuery } from '@mui/material';

const menuItems = [
  { path: "/admin", icon: <RiDashboard2Line />, label: "Admin panel" },
  { path: "/admin/appointments", icon: <FaRegCalendarAlt />, label: "Rezervacije" },
  { path: "/admin/comments", icon: <FaComments />, label: "Comments" },
  { path: "/admin/gallery", icon: <FaImages />, label: "Gallery" },
  { path: "/admin/packages", icon: <FaBoxes />, label: "Packages" },
  { path: "/admin/users", icon: <FaUsers />, label: "Users" },
  { path: "/admin/hero", icon: <FaStar />, label: "Hero" },
  { path: "/admin/static-config", icon: <LiaThemeco />, label: "Sekcije" },
  { path: "/admin/blogs", icon: <FaBlog />, label: "Blogovi" },
  { path: "/admin/shop", icon: <FaShoppingBasket />, label: "Shop" },
  { path: "/admin/orders", icon: <FaBox />, label: "Narudžbenice" },
];

const Sidebar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isFabOpen = Boolean(anchorEl);
  const isMobile = useMediaQuery('(max-width: 767px)');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* Sidebar for larger screens */}
      <div className={`fixed left-0 top-13 h-full ${isMobile ? 'hidden' : 'hover:w-64'} w-16 bg-gray-800 bg-opacity-70 backdrop-blur text-white flex flex-col transition-all duration-300 ease-in-out z-30`}>
        {menuItems.map(({ path, icon, label }) => (
          <Link key={path} to={path} className="sidebar-item">
            <span className='sidebar-icon'>{icon}</span>
            <span className="sidebar-text">{label}</span>
          </Link>
        ))}
      </div>

      {/* Floating Action Button (FAB) for smaller screens */}
      {isMobile && (
        <div className="fixed bottom-4 right-4 z-40">
          <Fab color="primary" onClick={handleClick}>
            <FaBars />
          </Fab>
          <Menu anchorEl={anchorEl} open={isFabOpen} onClose={handleClose}>
            {menuItems.map(({ path, icon, label }) => (
              <MenuItem key={path} onClick={handleClose}>
                <Link to={path} className="flex items-center w-[25vh]">
                  {icon}
                  <span className="ml-2">{label}</span>
                </Link>
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </>
  );
};

export default Sidebar;
