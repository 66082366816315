import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent, CardMedia, Grid, IconButton, Alert, Step, StepLabel, Stepper } from '@mui/material';
import api from '../../../API/api';
import { CreateOrderDto } from '../../../types/order.type';
import { CreateOrderItemDto } from '../../../types/order.item.type';
import User from '../../../types/user.type';
import { ApiConfig } from '../../../config/api.config';
import { FaTrash } from 'react-icons/fa';
import { Product } from '../../../interfaces/product.interface';

const steps = ['Korisnik', 'Artikli', 'Pregled narudžbenice'];

const CreateOrder: React.FC = () => {
  const [orderData, setOrderData] = useState<CreateOrderDto>({ usersId: 0, totalAmount: '0', status: 'pending' });
  const [users, setUsers] = useState<User[]>([]);
  const [orderItems, setOrderItems] = useState<CreateOrderItemDto[]>([]);
  const [newItem, setNewItem] = useState<{ productId: number; quantity: number; price: number; oldPrice: number; additionalDiscount: number }>({ productId: 0, quantity: 1, price: 0, oldPrice: 0, additionalDiscount: 0 });
  const [product, setProduct] = useState<Product | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [quantity, setQuantity] = useState<number>(1);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await api('/api/users', 'get', null, 'admin');
      if (response.status === 'ok') {
        setUsers(response.data);
      }
    };
    fetchUsers();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrderData({
      ...orderData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddItem = () => {
    if (product) {
      const discountFromProduct = product.discount ? parseFloat(product.discount.amount || '0') : 0;
      const additionalDiscount = newItem.additionalDiscount || 0;
      const itemPrice = calculateFinalPrice(product.price, discountFromProduct, additionalDiscount);
      console.log(itemPrice);
      const updatedItem: CreateOrderItemDto = {
        productId: product.productId,
        productImage: product.imageUrl,
        productName: product.name,
        quantity,
        price: itemPrice,
        oldPrice: product.price,
        additionalDiscount: additionalDiscount.toString(),
        discount: (discountFromProduct + additionalDiscount).toString()
      };
      setOrderItems([...orderItems, updatedItem]);
      setOrderData({
        ...orderData,
        totalAmount: (parseFloat(orderData.totalAmount) + itemPrice * quantity).toString(),
      });
      setNewItem({ productId: 0, quantity: 1, price: 0, oldPrice: 0, additionalDiscount: 0 });
      setProduct(null); 
      setQuantity(1);
      setDialogOpen(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setProduct(null);
    setQuantity(1);
    setAlertMessage(null);
    setNewItem({ productId: 0, quantity: 1, price: 0, oldPrice: 0, additionalDiscount: 0 });
  };

  const handleProductSearch = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const productId = Number(newItem.productId);
      const productResponse = await api(`/api/products/${productId}`, 'get', null, 'admin');
      if (productResponse.status === 'ok') {
        setProduct(productResponse.data);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!orderData.usersId || orderItems.length === 0) {
      setAlertMessage('Molimo vas da odaberete korisnika i dodate barem jedan artikal.');
      return;
    }
    const orderPayload = {
      ...orderData,
      status: 'pending',
      totalAmount: totalSum.toString(),
      orderItems,
    };
    const response = await api('/api/orders', 'post', orderPayload, 'admin');
    if (response.status === 'ok') {
      navigate('/admin/orders');
    }
  };

  const calculateFinalPrice = (price: number, discountFromProduct: number, additionalDiscount: number) => {
    const priceWithoutTax = price / (117 / 100);
    const discountedPrice = priceWithoutTax - (priceWithoutTax * discountFromProduct) / 100;
    const finalPrice = discountedPrice - (discountedPrice * additionalDiscount) / 100;
    const tax = finalPrice * 0.17;
    return finalPrice + tax;
  };

  const totalSum = orderItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
  const totalTax = totalSum/(117/100);

  const handleRemoveItem = (index: number) => {
    const updatedItems = [...orderItems];
    const removedItem = updatedItems.splice(index, 1)[0];
    setOrderItems(updatedItems);
    setOrderData({
      ...orderData,
      totalAmount: (parseFloat(orderData.totalAmount) - removedItem.price * removedItem.quantity).toString(),
    });
  };

  const handleQuantityChange = (index: number, newQuantity: number) => {
    const updatedItems = [...orderItems];
    updatedItems[index].quantity = newQuantity;
    setOrderItems(updatedItems);
  };

  const handleAdditionalDiscountChange = (index: number, additionalDiscount: number) => {
    const updatedItems = [...orderItems];
    const currentItem = updatedItems[index];
    const discountFromProduct = parseFloat(currentItem.discount || '0') - parseFloat(currentItem.additionalDiscount || '0');
    const productPrice = parseFloat(currentItem.oldPrice?.toString() || '0');
    const validAdditionalDiscount = isNaN(additionalDiscount) ? 0 : additionalDiscount;
    currentItem.additionalDiscount = validAdditionalDiscount.toString();
    const totalDiscount = discountFromProduct + validAdditionalDiscount;
    currentItem.price = calculateFinalPrice(productPrice, totalDiscount, 0);
    currentItem.discount = totalDiscount.toString();
    setOrderItems(updatedItems);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-semibold mb-4">Kreiraj narudžbenicu</h1>
      {alertMessage && (
        <Alert severity="warning" onClose={() => setAlertMessage(null)}>{alertMessage}</Alert>
      )}

      <Stepper className='mb-4 w-full' activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <form className='w-full flex justify-center' onSubmit={handleSubmit}>
        {activeStep === 0 && (
          <div className='container flex flex-col'>
            <TextField
              select
              label="Odaberi korisnika"
              name="usersId"
              value={orderData.usersId}
              onChange={handleChange}
              fullWidth
              margin="normal"
            >
              {users.map((user) => (
                <MenuItem key={user.usersId} value={user.usersId}>
                  {user.firstName} {user.lastName}
                </MenuItem>
              ))}
            </TextField>
            <div className="flex justify-end mb-4 border-t-2 pt-2">
              <Button variant='outlined' disabled={!orderData.usersId} onClick={handleNext}>
                Dalje
              </Button>
            </div>
          </div>
        )}

        {activeStep === 1 && (
          <div className='flex flex-col items-center w-full '>
            {orderItems.length <= 0 && (
              <div className="flex justify-center items-center p-5 container h-52 bg-gray-100 rounded-md">
                <Button variant="outlined" color="primary" onClick={() => setDialogOpen(true)}>
                  Dodaj artikal
                </Button>
              </div>
            )}

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <DialogTitle>{orderItems.some(item => item.productId === product?.productId) ? 'Uredi artikal' : 'Dodaj artikal'}</DialogTitle>
              <DialogContent sx={{padding:"3px"}}>
                <div className='pl-4 pr-4'>
              <TextField
                      label="Broj artikla"
                      name="productId"
                      type="number"
                      value={newItem.productId}
                      onChange={(e) => setNewItem({ ...newItem, productId: Number(e.target.value) })}
                      onKeyDown={handleProductSearch}
                      fullWidth
                      margin="normal"
                     
                    /></div>
                {orderItems.some(item => item.productId === product?.productId) ? (
                  orderItems.filter(item => item.productId === product?.productId).map((filteredItem, index) => {
                    const originalIndex = orderItems.findIndex(item => item.productId === filteredItem.productId);

                    return (
                      <React.Fragment key={filteredItem.productId}>
                        <Alert severity="warning" variant='outlined'>Artikal <b>{filteredItem.productName}</b> postoji već u narudžbenici. Da li želite promjeniti količinu?</Alert>
                        <Typography className='pt-2'>Na stanju: {product?.quantity! - filteredItem.quantity!}</Typography>
                        <TextField
                          label="Nova količina"
                          type="number"
                          value={filteredItem.quantity}
                          onChange={(e) => handleQuantityChange(originalIndex, parseInt(e.target.value))}
                          fullWidth
                          margin="normal"
                        />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <>
                    
                    {product && (
                      <Card className='flex flex-col md:flex-row' sx={{border:"0px", boxShadow:"none"}}>
                        <CardMedia
                          component="img"
                          // za testiranje
                          className='w-full md:max-w-[18rem]'
                          image={ApiConfig.API_URL + "/uploads/products/" + product.productId + "/" + product.imageUrl}
                          alt={product.name}
                        />

                        <CardContent>
                          <Typography variant="h6">{product.name}</Typography>
                          <Typography variant="body1">Cijena: {product.price} KM</Typography>
                          <Typography variant="body1">Na stanju: {product.quantity}</Typography>
                          {product.discount && (<Typography variant="body1">Popust {product.discount.amount}%</Typography>)}
                          {product.quantity === 0 && (
                            <Alert variant="standard" color="error">
                              Nema artikla na skladištu!
                            </Alert>
                          )}
                          <TextField
                            label="Količina"
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(Math.min(Number(e.target.value), product.quantity))}
                            fullWidth
                            margin="normal"
                            disabled={product.quantity === 0}
                          />
                          <TextField
                            label="Dodatni popust (%)"
                            type="number"
                            value={newItem.additionalDiscount}
                            onChange={(e) => setNewItem({ ...newItem, additionalDiscount: parseFloat(e.target.value) })}
                            fullWidth
                            margin="normal"
                            disabled={product.quantity === 0}
                          />
                        </CardContent>
                      </Card>
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions>
              {orderItems.some(item => item.productId === product?.productId) ? (
                <div>
                <Button onClick={() => handleCloseDialog()} color="secondary">
                  Izadji
                </Button>
                <Button onClick={() => handleCloseDialog()} color="primary">
                  Uredi
                </Button>
                </div>
                
              ) : (
                  <div>
                    <Button onClick={() => handleCloseDialog()} color="secondary">
                      Otkaži
                    </Button>
                    <Button
                      onClick={handleAddItem}
                      color="primary"
                      disabled={!!(product && product.quantity === 0)}
                    >
                        {product && product.quantity === 0 ? 'Artikl nije dostupan' : 'Dodaj'}
                      </Button>
                  </div>
              )}
                
              </DialogActions>
            </Dialog>

            <div className="mb-4">
              {orderItems.length > 0 && (
                <div>
                  <Typography variant="h6">Artikli u narudžbi</Typography>
                  <Grid container spacing={2}>
                    {orderItems.map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <Card className="flex flex-col sm:flex-row items-center p-4">
                          <CardMedia
                            component="img"
                            sx={{width: '151px'}}
                            image={ApiConfig.API_URL + "/uploads/products/" + item.productId + "/" + item.productImage}
                            alt={item.productName}
                          />
                          <CardContent className='flex-1 w-full sm:w-auto'>
                            <Typography variant="h6">{item.productName}</Typography>
                            <Typography variant="body1">Cijena: {Number(item.price).toFixed(2)} KM</Typography>
                            <TextField
                              label="Količina"
                              type="number"
                              value={item.quantity}
                              onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                              fullWidth
                              margin="normal"
                            />
                            <TextField
                              label="Dodatni popust (%)"
                              type="number"
                              value={item.additionalDiscount}
                              onChange={(e) => handleAdditionalDiscountChange(index, parseFloat(e.target.value))}
                              fullWidth
                              margin="normal"
                            />
                          </CardContent>
                          <IconButton onClick={() => handleRemoveItem(index)} color="error">
                            <FaTrash />
                          </IconButton>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  <div className="flex justify-center mb-4 mt-4">
                    <Button variant="outlined" color="primary" onClick={() => setDialogOpen(true)}>
                      Dodaj novi artikal
                    </Button>
                  </div>
                  <div className='flex items-end flex-col mt-4 border-t-2 pt-2'>
                    <Typography>Cijena bez PDV: {totalTax.toFixed(2)} KM</Typography>
                    <Typography>PDV: {(totalSum-totalTax).toFixed(2)} KM</Typography>
                    <Typography>Ukupno: {totalSum.toFixed(2)} KM</Typography>
                  </div>
                </div>
              )}
            </div>

            <div className="flex justify-between mt-4 w-full container mb-12">
              <Button variant='outlined' disabled={Number(activeStep) === 0} onClick={handleBack}>
                Nazad
              </Button>
              <Button variant='outlined' disabled={orderItems.length <= 0} onClick={handleNext}>
                Dalje
              </Button>
            </div>
          </div>
        )}

        {activeStep === 2 && (
          <div>
            <Typography variant="h6">Pregled narudžbine:</Typography>
            {users.filter(user => user.usersId === orderData.usersId).map((filteredUser) => (
              <Typography key={filteredUser.usersId}>
                {filteredUser.firstName} {filteredUser.lastName} - {filteredUser.email} - 
              </Typography>
            ))}
            <Typography>Cijena bez PDV1: {totalTax.toFixed(2)} KM</Typography>
            <Typography>PDV (17%): {(totalSum-totalTax).toFixed(2)} KM</Typography>
            <Typography>Ukupno: {totalSum.toFixed(2)} KM</Typography>
            <div className="flex justify-between mt-4 mb-12">
              <Button variant='outlined' disabled={Number(activeStep) === 1} onClick={handleBack}>
                Nazad
              </Button>
              <Button variant='outlined' color="success" type="submit">
                Potvrdi
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default CreateOrder;
