// EditAppointmentDialog.tsx
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import api from '../../API/api';
import Appointment from '../../types/appointment.type';

interface EditAppointmentDialogProps {
  open: boolean;
  onClose: () => void;
  appointmentId: number;
}

const EditAppointmentDialog: React.FC<EditAppointmentDialogProps> = ({ open, onClose, appointmentId }) => {
  const [appointment, setAppointment] = useState<Appointment>();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const getAppointment = async () => {
      try {
        const response = await api(`api/appointments/${appointmentId}`, 'get', {});
        setAppointment(response.data);
      } catch (error) {
        console.error('Error fetching appointment:', error);
      }
    };

    if (appointmentId) {
      getAppointment();
    }
  }, [appointmentId]);

  const handleSave = async () => {
    try {
      await api(`api/appointments/${appointmentId}`, 'put', appointment);
      onClose();
    } catch (error) {
      console.error('Error saving appointment:', error);
    }
  };

  const isDisabled = appointment?.status === 'confirmed';

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <DialogTitle>Izmjena rezervacije!</DialogTitle>
      
      <DialogContent>
        {appointment && (
          <>
            <TextField
              margin="dense"
              label="Ime korisnika"
              type="text"
              fullWidth
              variant="outlined"
              value={`${appointment.user?.firstName} ${appointment.user?.lastName}`}
              disabled
            />
            <TextField
              margin="dense"
              label="Email korisnika"
              type="email"
              fullWidth
              variant="outlined"
              value={appointment.user?.email}
              disabled
            />
            <TextField
              margin="dense"
              label="Usluga"
              type="text"
              fullWidth
              variant="outlined"
              value={appointment.service?.name}
              disabled
            />
            <TextField
              margin="dense"
              label="Cijena"
              type="text"
              fullWidth
              variant="outlined"
              value={appointment.service?.price}
              disabled
            />
            <TextField
              margin="dense"
              label="Frizer"
              type="text"
              fullWidth
              variant="outlined"
              value={`${appointment.stylist?.firstName} ${appointment.stylist?.lastName}`}
              disabled
            />
            <TextField
              margin="dense"
              label="Datum"
              type="date"
              fullWidth
              variant="outlined"
              value={appointment.date}
              onChange={(e) => setAppointment({ ...appointment, date: e.target.value })}
              disabled={isDisabled}
            />
            <TextField
              margin="dense"
              label="Vrijeme"
              type="time"
              fullWidth
              variant="outlined"
              value={appointment.time?.slice(0, 5)}
              onChange={(e) => setAppointment({ ...appointment, time: e.target.value })}
              disabled={isDisabled}
              inputProps={{
                step: 300, // 5 minute steps
              }}
            />
            <Select
              margin="dense"
              label="Status rezervacije"
              fullWidth
              variant="outlined"
              value={appointment.status}
              onChange={(e) => setAppointment({ ...appointment, status: e.target.value })}
              disabled={isDisabled}
            >
              <MenuItem value="pending">Na čekanju</MenuItem>
              <MenuItem value="confirmed">Potvrđeno</MenuItem>
              <MenuItem value="cancelled">Otkazano</MenuItem>
            </Select>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Izađi
        </Button>
        <Button onClick={handleSave} color="primary" disabled={isDisabled}>
          Snimi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAppointmentDialog;
