import React, { useState, useEffect, createContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import api, { saveToken, removeIdentity, checkAuth } from '../API/api';

interface AuthContextType {
  isAuthenticated: boolean;
  role: string | null;
  userId: number | null;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [role, setRole] = useState<string | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthStatus = async () => {
      const authStatus = await checkAuth();
      setIsAuthenticated(authStatus.isAuthenticated);
      setRole(authStatus.role || null);
      setUserId(authStatus.userId || null);
    };
    checkAuthStatus();
  }, [isAuthenticated]);

  const login = async (email: string, password: string) => {
    const response = await api('auth/login', 'post', { email, password });
    if (response.status === 'ok') {
      saveToken(response.data.access_token);
      setIsAuthenticated(true);
      setRole(response.data.role);
      setUserId(response.data.userId);
      navigate('/');
    } else {
      alert('Invalid email or password');
    }
  };

  const logout = () => {
    removeIdentity();
    setIsAuthenticated(false);
    setRole(null);
    setUserId(null);
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, role, userId, setIsAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
