import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import AppointmentDialog from './AppointmentDialog';
import api, { ApiResponse } from '../../API/api';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { AppointmentInterface } from '../../interfaces/appointments.inteface';
import { formatTime } from '../../utils/date.utils';
import './customCalendar.css'; // Tvoj prilagođeni CSS fajl
import Section3 from '../StaticSectionDisplay/section-3';

// Definicija Skeleton Loader-a
const SkeletonLoader: React.FC = () => (
  <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center space-y-4">
    <div className="w-3/4 h-8 bg-gray-300 animate-pulse rounded"></div>
    <div className="w-full h-64 bg-gray-300 animate-pulse rounded"></div>
    <div className="w-full h-12 bg-gray-300 animate-pulse rounded"></div>
    <div className="w-full h-16 bg-gray-300 animate-pulse rounded"></div>
  </div>
);

const WorkingOurs: React.FC = () => {
  const today = new Date();
  const [appointments, setAppointments] = useState<AppointmentInterface[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(today);
  const [filteredAppointments, setFilteredAppointments] = useState<AppointmentInterface[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchAppointments = async () => {
    try {
      const response: ApiResponse = await api('/api/appointments', 'get', null, 'user');
      if (response.status === 'ok') {
        setAppointments(response.data);
      }
    } catch (error) {
      console.error('Error fetching appointments:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  useEffect(() => {
    const filterAppointments = () => {
      if (!selectedDate) {
        setFilteredAppointments([]);
        return;
      }
  
      const today = new Date();
      const filtered = appointments.filter(appointment => {
        const appointmentDate = new Date(appointment.date);
        const appointmentTime = new Date(`${appointment.date}T${appointment.time}`);
        const thirtyMinutesBefore = new Date(appointmentTime.getTime() - 30 * 60000);
        return (
          appointmentDate.toDateString() === selectedDate.toDateString() &&
          appointmentTime >= today &&
          appointmentTime >= thirtyMinutesBefore &&
          appointment.status === 'pending' &&
          appointment.userId === null
        );
      });
  
      setFilteredAppointments(filtered);
    };
  
    filterAppointments();
  }, [selectedDate, appointments]);
  
  const handleDateChange = (value: Value, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (value instanceof Date) {
      setSelectedDate(value);
    } else if (Array.isArray(value) && value.every(item => item instanceof Date)) {
      setSelectedDate(value[0]);
    } else if (value && typeof value === 'object' && 'from' in value && value.from instanceof Date) {
      setSelectedDate(value.from);
    } else {
      setSelectedDate(null);
    }
  };

  const handleReserveClick = (appointmentId: number) => {
    setSelectedAppointmentId(appointmentId);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setSelectedAppointmentId(null);
    fetchAppointments();
  };

  if (loading) {
    return (
      <section className="py-16 bg-gray-200 text-gray-900 text-center min-h-screen flex flex-col justify-center w-full">
        <div className="flex flex-col md:flex-row justify-center gap-4 m-2">
          <Section3 />
          <SkeletonLoader />
        </div>
      </section>
    );
  }

  return (
    /*bio bg-gray-200 */
    <section className="py-16 text-gray-900 text-center min-h-screen flex flex-col justify-center w-full"
    style={{
      background: 'radial-gradient(circle, #473f33, #282828)'
    }}
    >
      <div className="flex flex-col md:flex-row justify-center gap-4 m-2">
        <Section3 />
        <div className="bg-white shadow-lg rounded-lg p-6 relative flex justify-center flex-col items-center">
          <h3 className="text-2xl font-semibold mb-4">Rezervacije</h3>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            tileDisabled={({ date }) => date < new Date()}
            className="custom-calendar"
            tileClassName={({ date, view }) => {
              if (view === 'month' && date.getDay() === 0) {
                return 'highlight';
              }
              return '';
            }}
          />
          {selectedDate && (
            <div className='w-full'>
              {filteredAppointments.length > 0 ? (
                <div className="pl-2 pr-2 pt-3 pb-3 bg-[#f0f0f0] border-t-[2px]">
                  <ul>
                    {filteredAppointments.map(appointment => (
                      <li key={appointment.appointmentsId} className="mb-2 text-md flex justify-between items-center">
                        {formatTime(appointment.time)} - {appointment.service.name}
                        <button
                          onClick={() => handleReserveClick(appointment.appointmentsId)}
                          className="ml-4 px-2 py-1 bg-black hover:bg-[#c59d5f] text-white uppercase text-sm"
                        >
                          Rezerviši
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className="pl-2 pr-2 pt-3 pb-3 bg-[#f0f0f0] text-sm border-t-2 text-red-600">Nema termina za ovaj datum</div>
              )}
            </div>
          )}
        </div>
      </div>
      {dialogOpen && selectedAppointmentId && (
        <AppointmentDialog
          appointmentId={selectedAppointmentId}
          onClose={closeDialog}
          open={dialogOpen}
        />
      )}
    </section>
  );
};

export default WorkingOurs;
