// src/App.tsx
import React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './API/AuthContext';
import HomeView from './components/HomeView';
import LoginForm from './components/LoginForm';
import AdminDashboard from './components/AdminDashboard';
import CommentsManagement from './components/CommentsManagement';
import GalleryManagement from './components/GalleryManagement';
import PackagesManagement from './components/PackagesManagement';
import UsersManagement from './components/UsersManagement';
import AppointmentsCalendar from './components/Appointments/AppointmentsList';
import HeroManagement from './components/Hero/HeroManagement';
import SectionAdmin from './components/StaticSectionDisplay/static-section.management';
import Navbar from './components/Navbar';
import Profile from './components/Profile/Profile';
import BlogList from './components/BlogPosts/admin/BlogList';
import AddBlog from './components/BlogPosts/admin/AddBlog';
import EditBlog from './components/BlogPosts/admin/EditBlog';
import BlogPostDetail from './components/BlogPosts/BlogPostDetails';
import RegisterForm from './components/RegisterForm';
import MasonryBlogPosts from './components/BlogPosts/MasonryBlogPosts';
import BlogPostsByCategory from './components/BlogPosts/BlogPostsByCategory';
import BlogPostsByTag from './components/BlogPosts/BlogPostsByTag';
import ShopList from './components/Shop/admin/ShopList';
import CreateOrderItem from './components/Orders/CreateOrderItem';
import OrderItemsList from './components/Orders/OrderItemsList';
import UpdateOrderItem from './components/Orders/UpdateOrderItem';
import OrdersList from './components/Orders/admin/OrdersList';
import UpdateOrder from './components/Orders/admin/UpdateOrder';
import CreateOrder from './components/Orders/admin/CreateOrder';
import OrderDetails from './components/Orders/OrderDetails';
import ProductDetails from './components/Shop/client/ProductDetails';
import Shopping from './components/Shop/client/Shopping';

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/blog" element={<MasonryBlogPosts />} />
          <Route path="/blog/:slug" element={<BlogPostDetail />} />
          <Route path="/blog/category/:categoryName" element={<BlogPostsByCategory />} />
          <Route path="/blog/tag/:tag" element={<BlogPostsByTag />} />
          <Route path="/shop" element={<Shopping />} />
          <Route path="/products/:productId" element={<ProductDetails />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/admin" element={<AdminDashboard />}>
            <Route path="comments" element={<CommentsManagement />} />
            <Route path="gallery" element={<GalleryManagement />} />
            <Route path="packages" element={<PackagesManagement />} />
            <Route path="users" element={<UsersManagement />} />
            <Route path="appointments" element={<AppointmentsCalendar />} />
            <Route path="hero" element={<HeroManagement />} />
            <Route path="static-config" element={<SectionAdmin />} />
            <Route path="blogs" element={<BlogList />} />
            <Route path="blogs/add" element={<AddBlog />} />
            <Route path="blogs/edit/:slugParam" element={<EditBlog />} />
            <Route path="shop" element={<ShopList />} />
            <Route path="orders" element={<OrdersList />} />
            <Route path="orders/create" element={<CreateOrder />} />
            <Route path="orders/edit/:orderId" element={<UpdateOrder />} />
            <Route path="orders/view/:orderId" element={<OrderDetails />} />
            <Route path="order-items" element={<OrderItemsList />} />
            <Route path="order-items/create/:orderId" element={<CreateOrderItem />} />
            <Route path="order-items/edit/:orderId" element={<UpdateOrderItem />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
