import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent, CardMedia, Grid, IconButton, Alert } from '@mui/material';
import api from '../../../API/api';
import { UpdateOrderDto } from '../../../types/order.type';
import { UpdateOrderItemDto } from '../../../types/order.item.type';
import { ApiConfig } from '../../../config/api.config';
import { FaTrash } from 'react-icons/fa';
import { Product } from '../../../interfaces/product.interface';

const UpdateOrder: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [orderData, setOrderData] = useState<UpdateOrderDto | null>(null);
  const [orderItems, setOrderItems] = useState<UpdateOrderItemDto[]>([]);
  const [newItem, setNewItem] = useState<{ productId: number; quantity: number; price: number; oldPrice: number; additionalDiscount: number }>({ productId: 0, quantity: 1, price: 0, oldPrice: 0, additionalDiscount: 0 });
  const [product, setProduct] = useState<Product | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [quantity, setQuantity] = useState<number>(1);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrder = async () => {
      const response = await api(`/api/orders/${orderId}`, 'get', null, 'admin');
      if (response.status === 'ok') {
        setOrderData(response.data);
        setOrderItems(response.data.orderItems);
      }
    };
    fetchOrder();
  }, [orderId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (orderData) {
      setOrderData({
        ...orderData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setProduct(null);
    setQuantity(1);
    setAlertMessage(null);
    setNewItem({ productId: 0, quantity: 1, price: 0, oldPrice: 0, additionalDiscount: 0 });
  }

  const handleAddItem = () => {
    if (product && orderData) {
      const discountFromProduct = product.discount ? parseFloat(product.discount.amount || '0') : 0;
      const additionalDiscount = newItem.additionalDiscount || 0;
      const itemPrice = calculateFinalPrice(product.price, discountFromProduct, additionalDiscount);
      const updatedItem: UpdateOrderItemDto = {
        productId: product.productId,
        product:{
          name: product.name,
          imageUrl: product.imageUrl
        },
        quantity,
        price: itemPrice,
        oldPrice: product.price,
        additionalDiscount: additionalDiscount.toString(),
        discount: (discountFromProduct + additionalDiscount).toString()
      };
      setOrderItems([...orderItems, updatedItem]);
      setOrderData({
        ...orderData,
        totalAmount: (parseFloat(orderData.totalAmount) + itemPrice * quantity).toString(),
      });
      setNewItem({ productId: 0, quantity: 1, price: 0, oldPrice: 0, additionalDiscount: 0 });
      setProduct(null);
      setQuantity(1);
      setDialogOpen(false);
    }
  };

  const handleProductSearch = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const productId = Number(newItem.productId);
      const productResponse = await api(`/api/products/${productId}`, 'get', null, 'admin');
      if (productResponse.status === 'ok') {
        setProduct(productResponse.data);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!orderData || orderItems.length === 0) {
      setAlertMessage('Molimo vas da dodate barem jedan artikal.');
      return;
    }
    const orderPayload = {
      ...orderData,
      totalAmount: totalSum.toString(),
      orderItems,
    };
    const response = await api(`/api/orders/${orderId}`, 'put', orderPayload, 'admin');
    if (response.status === 'ok') {
      navigate('/admin/orders');
    }
  };

  const calculateFinalPrice = (price: number, discountFromProduct: number, additionalDiscount: number) => {
    const priceWithoutTax = price / (117 / 100);
    const discountedPrice = priceWithoutTax - (priceWithoutTax * discountFromProduct) / 100;
    const finalPrice = discountedPrice - (discountedPrice * additionalDiscount) / 100;
    const tax = finalPrice * 0.17;
    return finalPrice + tax;
  };

  const totalSum = orderItems.reduce((sum, item) => sum + item.price! * item.quantity!, 0);

  const handleRemoveItem = (index: number) => {
    const updatedItems = [...orderItems];
    const removedItem = updatedItems.splice(index, 1)[0];
    setOrderItems(updatedItems);
    if (orderData) {
      setOrderData({
        ...orderData,
        totalAmount: (parseFloat(orderData.totalAmount) - removedItem.price! * removedItem.quantity!).toString(),
      });
    }
  };

  const handleQuantityChange = (index: number, newQuantity: number) => {
    const updatedItems = [...orderItems];
    updatedItems[index].quantity = newQuantity;
    setOrderItems(updatedItems);
  };

  const handleAdditionalDiscountChange = (index: number, additionalDiscount: number) => {
    const updatedItems = [...orderItems];
    const currentItem = updatedItems[index];

    if (!currentItem.oldPrice) {
        currentItem.oldPrice = currentItem.price;
    }

    const validAdditionalDiscount = isNaN(additionalDiscount) ? 0 : additionalDiscount;

    currentItem.additionalDiscount = validAdditionalDiscount.toString();

    const originalPrice = parseFloat(currentItem.oldPrice?.toString() ?? '0');
    const productDiscount = parseFloat(currentItem.discount?.toString() ?? '0');

    const discountedPrice = originalPrice - (originalPrice * (productDiscount + validAdditionalDiscount) / 100);

    currentItem.price = parseFloat(discountedPrice.toFixed(2));

    setOrderItems(updatedItems);
};

  
  return (
    <div className="p-4">
      <Typography variant="h4" gutterBottom>Izmijeni narudžbu</Typography>
      {alertMessage && (
        <Alert severity="warning" onClose={() => setAlertMessage(null)}>{alertMessage}</Alert>
      )}
      {orderData && (
        <form onSubmit={handleSubmit}>
          <TextField
            select
            label="Status narudžbe"
            name="status"
            value={orderData.status}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {['pending', 'processed', 'shipped', 'delivered', 'cancelled'].map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>

          {orderItems.length <= 0 && (
              <div className="flex justify-center items-center p-5 container h-52 bg-gray-100 rounded-md">
                <Button variant="outlined" color="primary" onClick={() => setDialogOpen(true)}>
                  Dodaj artikal
                </Button>
              </div>
            )}

<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <DialogTitle>{orderItems.some(item => item.productId === product?.productId) ? 'Uredi artikal' : 'Dodaj artikal'}</DialogTitle>
              <DialogContent sx={{padding:"3px"}}>
                <div className='pl-4 pr-4'>
              <TextField
                      label="Broj artikla"
                      name="productId"
                      type="number"
                      value={newItem.productId}
                      onChange={(e) => setNewItem({ ...newItem, productId: Number(e.target.value) })}
                      onKeyDown={handleProductSearch}
                      fullWidth
                      margin="normal"
                     
                    /></div>
                {orderItems.some(item => item.productId === product?.productId) ? (
                  orderItems.filter(item => item.productId === product?.productId).map((filteredItem, index) => {
                    const originalIndex = orderItems.findIndex(item => item.productId === filteredItem.productId);

                    return (
                      <React.Fragment key={filteredItem.productId}>
                        <Alert severity="warning" variant='outlined'>Artikal <b>{filteredItem.product.name}</b> postoji već u narudžbenici. Da li želite promjeniti količinu?</Alert>
                        <Typography className='pt-2'>Na stanju: {product?.quantity! - filteredItem.quantity!}</Typography>
                        <TextField
                          label="Nova količina"
                          type="number"
                          value={filteredItem.quantity}
                          onChange={(e) => handleQuantityChange(originalIndex, parseInt(e.target.value))}
                          fullWidth
                          margin="normal"
                        />
                      </React.Fragment>
                    );
                  })
                ) : (
                  <>
                    
                    {product && (
                      <Card className='flex flex-col md:flex-row' sx={{border:"0px", boxShadow:"none"}}>
                        <CardMedia
                          component="img"
                          // za testiranje
                          className='w-full md:max-w-[18rem]'
                          image={ApiConfig.API_URL + "/uploads/products/" + product.productId + "/" + product.imageUrl}
                          alt={product.name}
                        />

                        <CardContent>
                          <Typography variant="h6">{product.name}</Typography>
                          <Typography variant="body1">Cijena: {product.price} KM</Typography>
                          <Typography variant="body1">Na stanju: {product.quantity}</Typography>
                          {product.discount && (<Typography variant="body1">Popust {product.discount.amount}%</Typography>)}
                          {product.quantity === 0 && (
                            <Alert variant="standard" color="error">
                              Nema artikla na skladištu!
                            </Alert>
                          )}
                          <TextField
                            label="Količina"
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(Math.min(Number(e.target.value), product.quantity))}
                            fullWidth
                            margin="normal"
                            disabled={product.quantity === 0}
                          />
                          <TextField
                            label="Dodatni popust (%)"
                            type="number"
                            value={newItem.additionalDiscount}
                            onChange={(e) => setNewItem({ ...newItem, additionalDiscount: parseFloat(e.target.value) })}
                            fullWidth
                            margin="normal"
                            disabled={product.quantity === 0}
                          />
                        </CardContent>
                      </Card>
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions>
              {orderItems.some(item => item.productId === product?.productId) ? (
                <div>
                <Button onClick={() => handleCloseDialog()} color="secondary">
                  Izadji
                </Button>
                <Button onClick={() => handleCloseDialog()} color="primary">
                  Uredi
                </Button>
                </div>
                
              ) : (
                  <div>
                    <Button onClick={() => handleCloseDialog()} color="secondary">
                      Otkaži
                    </Button>
                    <Button
                      onClick={handleAddItem}
                      color="primary"
                      disabled={!!(product && product.quantity === 0)}
                    >
                        {product && product.quantity === 0 ? 'Artikl nije dostupan' : 'Dodaj'}
                      </Button>
                  </div>
              )}
                
              </DialogActions>
            </Dialog>

          
          <Grid className='' spacing={2} >
          <Typography variant="h6" gutterBottom>Artikli u narudžbi</Typography>
          <div className='flex flex-col gap-2 w-full'>
            {orderItems.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Card className="flex flex-col sm:flex-row items-center p-4">
                  <CardMedia
                    component="img"
                    sx={{width: '151px'}}
                    image={ApiConfig.API_URL + "/uploads/products/" + item.productId + "/" + item.product.imageUrl}
                    alt={item.product.name}
                  />
                  <CardContent className='flex-1 w-full sm:w-auto'>
                    <Typography variant="h6">{item.product.name}</Typography>
                    <Typography variant="body1">Cijena: {Number(item.price).toFixed(2)} KM</Typography>
                    <TextField
                      label="Količina"
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Dodatni popust (%)"
                      type="number"
                      value={item.additionalDiscount}
                      onChange={(e) => handleAdditionalDiscountChange(index, parseFloat(e.target.value))}
                      fullWidth
                      margin="normal"
                    />
                  </CardContent>
                  <IconButton onClick={() => handleRemoveItem(index)} color="error">
                    <FaTrash />
                  </IconButton>
                </Card>
              </Grid>
            ))}

            {orderItems.length > 0 && (
              <div className="mb-4 mt-4 w-full flex justify-center">
              <Button variant="outlined" color="primary" onClick={() => setDialogOpen(true)}>
                Dodaj novi artikal
              </Button>
            </div>
            )}
            </div>
          </Grid>

          <div className="flex justify-end mt-4 border-t-2 pt-3">
            <Typography>Ukupno: {totalSum.toFixed(2)} KM</Typography>
          </div>

          <div className="flex justify-end mt-4 mb-16">
            <Button type="submit" variant='outlined' color="primary">
              Spasi promjene
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default UpdateOrder;
