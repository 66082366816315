import React, { useState } from 'react';
import api from '../API/api';
import { useNavigate } from 'react-router-dom';
import { Stepper, Step } from '@mui/material';
import { StepLabel, Button, TextField } from '@mui/material';
import { FaUpload } from 'react-icons/fa';

// Register Form Component
const RegisterForm: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const steps = ['Osnovni podaci', 'Profilna fotografija'];

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    if (profileImage) {
      formData.append('profileImage', profileImage);
    }

    const response = await api('/auth/register', 'post', formData, 'guest', { useMultipartFormData: true });
    if (response.status === 'ok') {
      alert('Registracija uspješna!');
      navigate('/login');
    } else {
      alert('Došlo je do greške pri registraciji');
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setProfileImage(file);
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#f5f5f5] p-3">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md border border-[#e0e0e0]">
        <h1 className="text-3xl font-bold mb-8 text-[#282828] text-center">Registrujte se</h1>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 ? (
          <div className="mt-6">
            <TextField
              label="Ime"
              fullWidth
              variant="outlined"
              margin="normal"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              label="Prezime"
              fullWidth
              variant="outlined"
              margin="normal"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              label="Email"
              fullWidth
              variant="outlined"
              margin="normal"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Lozinka"
              fullWidth
              variant="outlined"
              margin="normal"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        ) : (
          <div className="mt-6">
            <div className="flex flex-col items-center">
              {imagePreview && <img src={imagePreview} alt="Preview" className="mb-4 h-32 w-32 object-cover rounded-full" />}
              <Button
                variant="contained"
                component="label"
                startIcon={<FaUpload />}
              >
                Odaberite sliku
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Button>
            </div>
          </div>
        )}
        <div className="mt-6 flex justify-between">
          <Button
            variant="outlined"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            Povratak
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
          >
            {activeStep === steps.length - 1 ? 'Registrujte se' : 'Dalje'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
