import React from 'react';
import { Paper, Typography } from '@mui/material';

interface UserPointsProps {
  points: any[];
}

const UserPoints: React.FC<UserPointsProps> = ({ points }) => {
  return (
    <div>
      <Typography variant="h6" className="mb-4">Poeni</Typography>
      {/* Implementirajte prikaz poena */}
    </div>
  );
};

export default UserPoints;
