import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Typography } from '@mui/material';
import api from '../../API/api';
import { UpdateOrderItemDto } from '../../types/order.item.type';

const UpdateOrderItem: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const [orderItemData, setOrderItemData] = useState<UpdateOrderItemDto>({ productId: 0, quantity: 0, price: 0, product:{name:'', imageUrl: null} });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrderItem = async () => {
            const response = await api(`/api/order-items/${orderId}`, 'get', undefined, 'admin');
            if (response.status === 'ok') {
                setOrderItemData(response.data);
            }
        };

        fetchOrderItem();
    }, [orderId]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrderItemData({
            ...orderItemData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const response = await api(`/api/order-items/${orderId}`, 'put', orderItemData, 'admin');
        if (response.status === 'ok') {
            navigate(`/orders/${response.data.orderId}`);
        }
    };

    return (
        <div className="p-4">
            <Typography variant="h4" gutterBottom>Update Order Item</Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Product Name"
                    name="productId"
                    value={orderItemData.productId}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Quantity"
                    name="quantity"
                    type="number"
                    value={orderItemData.quantity}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Price"
                    name="price"
                    type="number"
                    value={orderItemData.price}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" className="mt-4">
                    Update Order Item
                </Button>
            </form>
        </div>
    );
};

export default UpdateOrderItem;
