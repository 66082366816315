import React from 'react';

const GalleryManagement: React.FC = () => {
  return (
    <div className="gallery-management p-4">
      <h1 className="text-2xl font-semibold mb-4">Manage Gallery</h1>
      {/* Dodajte funkcionalnosti za upravljanje galerijom */}
    </div>
  );
};

export default GalleryManagement;
