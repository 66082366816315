import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { FaEdit, FaImage, FaTrash } from 'react-icons/fa';
import api, { ApiResponse } from '../../../API/api';
import ShopForm from './ShopForm';
import { ApiConfig } from '../../../config/api.config';

interface Product {
  productId: number;
  name: string;
  description: string;
  price: string;
  imageUrl: string | null;
  quantity: string;
  createdAt: string;
  updatedAt: string;
  productCategoryId: number;
  productCategory: {
    name: string;
  }
}

const ShopList: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isAddEditDialogOpen, setAddEditDialogOpen] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const response: ApiResponse = await api('/api/products', 'get', undefined, 'admin');
    if (response.status === 'ok') {
      setProducts(response.data);
    }
  };

  const openAddEditDialog = (product: Product | null) => {
    setSelectedProduct(product);
    setAddEditDialogOpen(true);
  };

  const openDeleteDialog = (product: Product) => {
    setSelectedProduct(product);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    if (selectedProduct) {
      const response: ApiResponse = await api(`/api/products/${selectedProduct.productId}`, 'delete', undefined, 'admin');
      if (response.status === 'ok') {
        setProducts(products.filter(product => product.productId !== selectedProduct.productId));
        setDeleteDialogOpen(false);
      } else {
        alert(response.data.message);
      }
    }
  };

  const handleProductUpdated = () => {
    fetchProducts();
    setAddEditDialogOpen(false);
  };

  return (
    <div className="p-3">
      <h1 className="text-2xl font-semibold mb-4">Shop</h1>
      <div className='w-full flex justify-end'>
      <Button
        variant="outlined"
        className="mb-4"
        onClick={() => openAddEditDialog(null)}
      >
        Dodaj novi artikal
      </Button>
      </div>
      <TableContainer component={Paper} className="rounded-lg shadow-md mt-4">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Slika</TableCell>
              <TableCell>Naziv</TableCell>
              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>Opis</TableCell>
              <TableCell>Cijena</TableCell>
              <TableCell>Stanje</TableCell>
              <TableCell>Kategorija</TableCell>
              <TableCell>Akcije</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map(product => (
              <TableRow key={product.productId}>
                <TableCell>{product.productId}</TableCell>
                <TableCell className="justify-start-start">
                {product.imageUrl ? (
                    <img
                      src={ApiConfig.API_URL + "/uploads/products/" + product.productId + "/" + product.imageUrl}
                      alt={product.name}
                      className="max-w-20 object-cover rounded-xl mx-auto shadow-lg hover:scale-105 transform transition duration-300 ease-in-out border border-gray-200"
                    />
                  ) : (
                    <FaImage className="text-gray-500 w-[50px] h-[70px]" />
                  )}
                </TableCell>
                <TableCell>{product.name.length > 34 ? `${product.name.slice(0,34)}...` : product.name}</TableCell>
                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{product.description.length > 100 ? 
                  `${product.description.slice(0, 420)}...` : 
                  product.description}</TableCell>
                <TableCell>{product.price}</TableCell>
                <TableCell>{product.quantity}</TableCell>
                <TableCell>{product.productCategory.name}</TableCell>
                <TableCell>
                  <div className="flex space-x-2">
                    <IconButton
                    className='w-9 h-9'
                      color="primary"
                      onClick={() => openAddEditDialog(product)}
                    >
                      <FaEdit />
                    </IconButton>
                    <IconButton
                    className='w-9 h-9'
                      color="error"
                      onClick={() => openDeleteDialog(product)}
                    >
                      <FaTrash />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Obriši artikal</DialogTitle>
        <DialogContent>
          <p>Da li ste sigurni da želite obrisati artikal</p>
          <div className="mt-4 flex justify-end">
            <Button
              variant="contained"
              color="error"
              className="mr-2"
              onClick={handleDelete}
            >
              Obriši
            </Button>
            <Button
              variant="outlined"
              onClick={() => setDeleteDialogOpen(false)}
            >
              Izađi
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Add/Edit Product Dialog */}
      <ShopForm
        isOpen={isAddEditDialogOpen}
        onClose={() => setAddEditDialogOpen(false)}
        product={selectedProduct}
        onProductUpdated={handleProductUpdated}
      />
    </div>
  );
};

export default ShopList;
