import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Typography } from '@mui/material';
import api from '../../API/api';
import { CreateOrderItemDto } from '../../types/order.item.type';

const CreateOrderItem: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const [orderItemData, setOrderItemData] = useState<CreateOrderItemDto>({ productId: 0, quantity: 0, price: 0 });
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrderItemData({
            ...orderItemData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const response = await api('/api/order-items', 'post', { ...orderItemData, orderId: Number(orderId) }, 'admin');
        if (response.status === 'ok') {
            navigate(`/orders/${orderId}`);
        }
    };

    return (
        <div className="p-4">
            <Typography variant="h4" gutterBottom>Create Order Item</Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Product Name"
                    name="productId"
                    value={orderItemData.productId}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Quantity"
                    name="quantity"
                    type="number"
                    value={orderItemData.quantity}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Price"
                    name="price"
                    type="number"
                    value={orderItemData.price}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" className="mt-4">
                    Create Order Item
                </Button>
            </form>
        </div>
    );
};

export default CreateOrderItem;
