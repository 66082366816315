import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'; // Dodan Link
import api, { ApiResponse } from '../../../API/api';
import { ApiConfig } from '../../../config/api.config';
import { FaTag, FaCartPlus } from 'react-icons/fa';
import Rating from '@mui/material/Rating';
import { Tab, Tabs, Box } from '@mui/material'; 
import { useAuth } from '../../../API/AuthContext';

interface Review {
  reviewId: number;
  rating: number;
  comment: string;
  createdAt: string;
  user: {
    usersId: number;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    profileImage: string;
  };
}

interface Discount {
  code: string;
  amount: string;
  type: string;
  description: string;
  startDate: string;
  endDate: string;
}

interface AttributeValue {
  attributeValueId: number;
  value: string;
  categoryAttributeValuesId: number;
  categoryAttribute: {
    categoryAttributeValuesId: number;
    name: string;
  };
}

interface Product {
  productId: number;
  name: string;
  description: string | null;
  price: string;
  imageUrl: string | null;
  quantity: number;
  createdAt: string;
  updatedAt: string | null;
  productCategory: {
    productCategoryId: number;
    name: string;
  };
  discount: Discount | null;
  reviews: Review[];
  attributeValues: AttributeValue[];
}

const ProductDetails: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<Product | null>(null);
  const [similarProducts, setSimilarProducts] = useState<Product[]>([]); // Slični proizvodi
  const [activeTab, setActiveTab] = useState<number>(0); 
  const { isAuthenticated, userId } = useAuth();
  const [rating, setRating] = useState<number>(1);
  const [comment, setComment] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(1);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response: ApiResponse = await api(`/api/products/${productId}`, 'get', undefined, 'guest');
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    const fetchSimilarProducts = async () => {
      try {
        const response: ApiResponse = await api('/api/products', 'get', undefined, 'guest');
        const allProducts: Product[] = response.data;
        
        // Filtriramo proizvode koji nisu trenutni i nasumično biramo 4
        const randomProducts = allProducts
          .filter(p => p.productId !== parseInt(productId!)) // Ne prikazuj trenutni proizvod
          .sort(() => 0.5 - Math.random()) // Nasumično miješanje proizvoda
          .slice(0, 4); // Uzmi 4 nasumična proizvoda

        setSimilarProducts(randomProducts);
      } catch (error) {
        console.error('Error fetching similar products:', error);
      }
    };

    fetchProductDetails();
    fetchSimilarProducts();
  }, [productId]);

  if (!product) {
    return <p>Učitavanje...</p>;
  }

  const formatPrice = (price: string, discount: Discount | null) => {
    if (discount) {
      const newPrice = calculateDiscountedPrice(Number(price), discount.amount).toFixed(2);
      return (
        <>
          <p className="text-red-500 font-semibold">{newPrice} KM</p>
          <p className="text-gray-500 line-through">{Number(price).toFixed(2)} KM</p>
        </>
      );
    }
    return <p className="text-lg font-semibold">{Number(price).toFixed(2)} KM</p>;
  };

  const handleAddToCart = async () => {
    if (!isAuthenticated) {
      alert('Morate biti prijavljeni za dodavanje proizvoda u korpu.');
      return;
    }

    try {
      setIsSubmitting(true);
      const cartData = {
        productId: product?.productId || 0,
        quantity,
        userId: userId || 0,
      };
      await api('/api/cart', 'post', cartData);
      alert('Proizvod je uspješno dodan u korpu!');
    } catch (error) {
      console.error('Greška prilikom dodavanja u korpu:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitReview = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isAuthenticated) {
      alert('Morate biti prijavljeni za dodavanje recenzije.');
      return;
    }

    try {
      setIsSubmitting(true);
      const reviewData = {
        rating,
        comment,
        productId: product?.productId || 0,
        userId: userId || 0,
      };
      await api('/api/reviews', 'post', reviewData);
      const updatedProduct: ApiResponse = await api(`/api/products/${productId}`, 'get', undefined, 'admin');
      setProduct(updatedProduct.data);
      setRating(1);
      setComment('');
    } catch (error) {
      console.error('Greška prilikom dodavanja recenzije:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const calculatePriceWithoutTax = (price: number): number => {
    return price/(1+0.17);
  };

  const calculateDiscountedPrice = (price: number, discountAmount: string | null): number => {
    if (!discountAmount) return price;
    const amount = parseFloat(discountAmount);
    const priceWithoutTax = calculatePriceWithoutTax(price);
    const priceWithDiscount = priceWithoutTax - (priceWithoutTax * (amount / 100));
    return priceWithDiscount * (1 + 0.17);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div className="container mx-auto p-4 max-w-screen-lg">
      {product.discount && (
        <div className="bg-yellow-400 text-center p-2 rounded-lg animate-bounce">
          {product.discount.description}: -{product.discount.amount}% uz kod {product.discount.code}!
        </div>
      )}

      <div className="flex flex-col lg:flex-row justify-between mt-4">
        <img
          src={ApiConfig.API_URL + "/uploads/products/" + product.productId + "/" + product.imageUrl}
          alt={product.name}
          className="lg:w-1/2 w-full object-cover rounded-lg"
        />
        <div className="lg:ml-8 mt-6 lg:mt-0 flex flex-col items-start w-full">
          <h1 className="text-2xl font-bold">{product.name}</h1>
          <p className="text-xl text-gray-500 line-through">Cijena: {product.price} KM</p>
          {product.discount && (
            <p className="text-xl font-semibold text-red-500">
              Cijena s popustom: {calculateDiscountedPrice(Number(product.price), product.discount.amount).toFixed(2)} KM
            </p>
          )}
          <p className='text-[10px] text-slate-500'>* U cijenu uračunat PDV (17%)</p>
          <p className="mt-4 text-gray-700">
            {product.description?.slice(0, 150)}...
          </p>

          <div className="mt-4 flex gap-4 w-full">
            <input
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
              min="1"
              className="border p-2 w-16"
            />
            <button
              onClick={handleAddToCart}
              className="flex items-center justify-center px-4 py-2 w-full text-xs font-bold tracking-[2px] border-[1px] border-black hover:bg-black text-black hover:text-white uppercase"
              disabled={isSubmitting}
            >
              <FaCartPlus className="mr-2 text-xl" />
              {isSubmitting ? 'Dodavanje...' : 'Dodaj u korpu'}
            </button>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="product details tabs">
            <Tab label="Opis" sx={{ textTransform: 'none', fontWeight: 'bold', color: activeTab === 0 ? 'black' : 'inherit' }} />
            <Tab label="Specifikacije" sx={{ textTransform: 'none', fontWeight: 'bold', color: activeTab === 1 ? 'black' : 'inherit' }} />
            <Tab label="Recenzije" sx={{ textTransform: 'none', fontWeight: 'bold', color: activeTab === 2 ? 'black' : 'inherit' }} />
          </Tabs>
        </Box>

        {activeTab === 0 && (
          <div className="mt-4">
            <p>{product.description}</p>
          </div>
        )}

        {activeTab === 1 && (
          <div className="mt-4">
            <ul>
              {product.attributeValues.map(attribute => (
                <li key={attribute.attributeValueId} className="mb-2">
                  <strong>{attribute.categoryAttribute.name}:</strong> {attribute.value}
                </li>
              ))}
            </ul>
          </div>
        )}

        {activeTab === 2 && (
          <div className="mt-4">
            {product.reviews.length > 0 ? product.reviews.map(review => (
              <div key={review.reviewId} className="mb-6 p-4 border rounded-lg">
                <div className="flex items-center mb-2">
                  <img
                    src={`${ApiConfig.API_URL}/uploads/users/${review.user.usersId}/${review.user.profileImage}`}
                    alt={`${review.user.firstName} ${review.user.lastName}`}
                    className="w-12 h-12 rounded-full mr-3"
                  />
                  <div>
                    <h4 className="font-semibold">{review.user.firstName} {review.user.lastName}</h4>
                    <Rating value={review.rating} precision={0.5} readOnly />
                    <p className="text-xs text-gray-500">{review.createdAt}</p>
                  </div>
                </div>
                <p className="mt-2 text-gray-800">{review.comment}</p>
              </div>
            )) : (
              <p>Nema recenzija za ovaj proizvod.</p>
            )}

            {/* Forma za dodavanje recenzije */}
            <h4 className="mt-6 text-lg font-semibold">Dodajte vašu recenziju:</h4>
            <form onSubmit={handleSubmitReview} className="mt-4">
              <div className="mb-4">
                <label htmlFor="rating" className="block text-sm font-medium">
                  Ocjena:
                </label>
                <Rating
                  id="rating"
                  value={rating}
                  onChange={(event, newValue) => setRating(newValue || 1)}
                  precision={0.5}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="comment" className="block text-sm font-medium">
                  Komentar:
                </label>
                <textarea
                  id="comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="w-full p-2 border rounded-lg"
                  rows={4}
                  required
                />
              </div>
              <button
                type="submit"
                className="flex items-center justify-center px-4 py-2 w-full text-xs font-bold tracking-[2px] border-[1px] border-black hover:bg-black text-black hover:text-white uppercase"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Slanje...' : 'Dodaj recenziju'}
              </button>
            </form>
          </div>
        )}
      </div>

      {/* Sekcija za slične proizvode */}
      <div className="mt-8">
            <h3 className="text-xl font-bold mb-4">Slični proizvodi</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {similarProducts.map((product) => (
                <Link
                  to={`/products/${product.productId}`}
                  key={product.productId}
                  className="block border p-4 rounded-lg hover:shadow-lg relative"
                >
                  <img
                    src={ApiConfig.API_URL + "/uploads/products/" + product.productId + "/" + product.imageUrl}
                    alt={product.name}
                    className="w-full h-48 object-cover mb-2 rounded-lg"
                  />
                  
                  {/* Badge za popust */}
                  {product.discount && (
                    <div className="absolute top-0 right-0 bg-red-500 text-white text-xs px-2 py-1 rounded-bl-lg rounded-tr-lg shadow-lg">
                      Popust: {product.discount.amount} %
                    </div>
                  )}

                  <h4 className="font-bold text-lg">{product.name}</h4>
                  
                  {/* Prikaz nove i stare cijene za slične proizvode */}
                  {product.discount ? (
                    <>
                      {formatPrice(product.price, product.discount)}
                    </>
                  ) : (
                    <p className="text-gray-600">Cijena: {Number(product.price).toFixed(2)} KM</p>
                  )}
                </Link>
              ))}
            </div>
          </div>
    </div>
  );
};

export default ProductDetails;
