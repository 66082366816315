import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Button,
} from '@mui/material';
import api from '../../../API/api';
import { formatDate, formatTime } from '../../../utils/date.utils';
import { MdExpandMore } from 'react-icons/md';
import { AppointmentInterface } from '../../../interfaces/appointments.inteface';

interface UserAppointmentsProps {
  appointments: AppointmentInterface[];
}

const UserAppointments: React.FC<UserAppointmentsProps> = ({ appointments }) => {
  const handleStatusChange = async (appointmentId: number, newStatus: string) => {
    try {
      await api(`/api/appointments/${appointmentId}`, 'put', { status: newStatus });
    } catch (error) {
      console.error('Greška pri ažuriranju statusa:', error);
    }
  };

  const statusLabel = (status: string) => {
    switch (status) {
      case 'pending':
        return 'Prihvaceno';
      case 'confirmed':
        return 'Potvrđeno';
      case 'cancelled':
        return 'Otkazano';
      default:
        return status;
    }
  };

  // Group appointments by date
  const groupedAppointments = appointments.reduce((acc, appointment) => {
    const date = formatDate(appointment.date);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(appointment);
    return acc;
  }, {} as Record<string, AppointmentInterface[]>);

  // Sort grouped appointments by date (latest first)
  const sortedDates = Object.keys(groupedAppointments).sort((a, b) => {
    return new Date(b.split('.').reverse().join('-')).getTime() - new Date(a.split('.').reverse().join('-')).getTime();
  });

  return (
    <div>
      {sortedDates.map((date) => {
        const appointments = groupedAppointments[date];
        const hasPending = appointments.some((appointment) => appointment.status === 'pending');

        return (
          <Accordion key={date} defaultExpanded={hasPending}>
            <AccordionSummary expandIcon={<MdExpandMore />}>
              <Typography >{date}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {appointments.map((appointment) => (
                <Box
                  key={appointment.appointmentsId}
                  className={`flex justify-between items-center p-3 mb-4 border rounded-lg bg-white
                              ${appointment.status === 'confirmed' ? 'border-green-300' : 'border-red-300'}`}
                >
                  <div className="flex flex-col">
                    <Typography variant="h6" className="font-bold text-yellow-600">
                      {appointment.service.name}
                    </Typography>
                    <Typography variant="body1" className="text-gray-800">
                      {appointment.service.description}
                    </Typography>
                    <Typography variant="body2" className="text-gray-600">
                      {formatTime(appointment.time)}
                    </Typography>
                  </div>
                  <Button
                    disabled={appointment.status !== 'pending'}
                    onClick={() => handleStatusChange(appointment.appointmentsId, 'confirmed')}
                    variant="outlined"
                    color={
                      appointment.status === 'pending'
                        ? 'primary'
                        : appointment.status === 'confirmed'
                        ? 'success'
                        : 'error'
                    }
                  >
                    {appointment.status === 'pending' ? 'Potvrdi' : statusLabel(appointment.status)}
                  </Button>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default UserAppointments;
