import React, { useEffect, useState } from 'react';
import api, { ApiResponse } from '../../API/api';

interface FieldData {
  value: string;
  type: 'text' | 'textarea';
}

interface SectionData {
  [key: string]: FieldData;
}

const Section2: React.FC = () => {
  const [data, setData] = useState<SectionData | null>(null);

  useEffect(() => {
    api('/api/json-update/section-2', 'get', {}, 'guest')
      .then((response: ApiResponse) => {
        if (response.status === 'ok') {
          // Pretvaranje tipa podataka iz odgovora u SectionData
          const sectionData = response.data.Sadržaj.reduce((acc: SectionData, item: { [key: string]: FieldData }) => {
            const [key, value] = Object.entries(item)[0];
            acc[key] = value;
            return acc;
          }, {} as SectionData);
          setData(sectionData);
        } else {
          console.error('Failed to fetch section data');
        }
      })
      .catch((error: any) => {
        console.error('There was an error fetching the section data!', error);
      });
  }, []);

  if (!data) {
    return (
      <section className="py-14 bg-[#282828] text-white text-center flex justify-center flex-col relative h-screen">
        <div className="relative w-full h-full flex flex-col items-center justify-center">
          {/* Skeleton loader za slogan */}
          <div className="w-full max-w-4xl h-24 bg-gray-300 animate-pulse rounded-lg mb-4"></div>
          
          {/* Skeleton loader za podnaslov */}
          <div className="w-1/2 h-8 bg-gray-300 animate-pulse rounded mb-2"></div>
          
          {/* Skeleton loader za naslov */}
          <div className="w-3/4 h-12 bg-gray-300 animate-pulse rounded mb-4"></div>
          
          {/* Skeleton loader za opis */}
          <div className="w-3/4 h-16 bg-gray-300 animate-pulse rounded mb-6"></div>
          
          {/* Skeleton loader za link */}
          <div className="w-32 h-10 bg-gray-300 animate-pulse rounded"></div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-13 bg-[#282828] text-white text-center flex justify-center flex-col relative min-h-[40vh] pt-3">
      <div className="md:hidden lg:flex relative sm:flex items-center justify-center w-full h-full">
        <h5 className="text-6xl sm:text-6xl md:text-7xl lg:text-[170px] text-[#473f33] font-Quentin whitespace-nowrap">
          {data.Slogan?.value}
        </h5>
      </div>
      <div className="relative z-30 -top-7 sm:-top-10 md:-top-12 lg:-top-20">
        <h3 className="text-[12px] font-Montserrat mb-2 uppercase tracking-[0.25em]">
          <span>- </span>{data.Podnaslov?.value}<span> -</span>
        </h3>
        <h2 className="text-2xl sm:text-3xl md:text-5xl lg:text-[45px] font-bold uppercase">
          {data.Naslov?.value}
        </h2>
      </div>
      <div className="relative z-30 lg:-top-20">
        <p className="max-w-3xl mx-auto mb-3 p-2">{data.Opis?.value}</p>
        <a href={data.Link?.value} className="px-10 text-xs font-bold tracking-[2px] py-3 border-[1px] border-white hover:bg-white hover:text-black uppercase">
          Saznaj više
        </a>
      </div>
    </section>
  );
}

export default Section2;
