import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import api from '../../API/api';
import Appointment from '../../types/appointment.type';
import './CreateAppointmentDialog.css'; // Dodajte ovu liniju za prilagođene stilove

interface CreateAppointmentDialogProps {
  open: boolean;
  onClose: () => void;
}

const CreateAppointmentDialog: React.FC<CreateAppointmentDialogProps> = ({ open, onClose }) => {
  const [appointment, setAppointment] = useState<Appointment>({
    userId: 0,
    serviceId: 0,
    stylistId: 2,
    date: '',
    time: '',
    status: 'pending' 
  });
  const [services, setServices] = useState<{ servicesId: number, name: string, price: string, duration: number, description: string }[]>([]);
  const [stylists, setStylists] = useState<{ stylistsId: number, firstName: string, lastName: string, specialty: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const today = new Date().toISOString().split('T')[0];
  const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

  useEffect(() => {
    const fetchServicesAndStylists = async () => {
      try {
        const [servicesResponse, stylistsResponse] = await Promise.all([
          api('api/services', 'get', {}),
          api('api/stylist', 'get', {})
        ]);
        setServices(servicesResponse.data);
        setStylists(stylistsResponse.data);
      } catch (error) {
        console.error('Error fetching services or stylists:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchServicesAndStylists();
  }, []);

  const handleSave = async () => {
    try {
      const { userId, status, ...appointmentToSave } = appointment;
      await api('api/appointments', 'post', appointmentToSave);
      onClose();
    } catch (error) {
      console.error('Error creating appointment:', error);
    }
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      {loading ? (
        <div className="loading-overlay">
          <CircularProgress className="loading-spinner" />
        </div>
      ) : (
        <>
          <DialogTitle>Dodaj novu rezervaciju</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Usluga"
              type="number"
              fullWidth
              variant="outlined"
              select
              value={appointment.serviceId}
              onChange={(e) => setAppointment({ ...appointment, serviceId: parseInt(e.target.value) })}
            >
              {services.map(service => (
                <MenuItem key={service.servicesId} value={service.servicesId}>
                  {service.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="dense"
              label="Frizer"
              type="number"
              fullWidth
              variant="outlined"
              select
              value={appointment.stylistId}
              onChange={(e) => setAppointment({ ...appointment, stylistId: parseInt(e.target.value) })}
            >
              {stylists.map(stylist => (
                <MenuItem key={stylist.stylistsId} value={stylist.stylistsId}>
                  {stylist.firstName + ' ' + stylist.lastName}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="dense"
              label="Datum"
              type="date"
              fullWidth
              variant="outlined"
              value={appointment.date ? appointment.date : today}
              onChange={(e) => setAppointment({ ...appointment, date: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Vrijeme"
              type="time"
              fullWidth
              variant="outlined"
              value={appointment.time ? appointment.time : currentTime}
              onChange={(e) => setAppointment({ ...appointment, time: e.target.value })}
              inputProps={{
                inputProps: {
                  step: 300, // interval unosa u sekundama (5 minuta)
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Izađi
            </Button>
            <Button onClick={handleSave} color="primary">
              Snimi
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CreateAppointmentDialog;
