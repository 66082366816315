import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Typography, Card, CardContent } from '@mui/material';
import api from '../../API/api';
import { OrderType } from '../../types/order.type';

const OrderDetails: React.FC = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const [order, setOrder] = useState<OrderType | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await api(`/api/orders/${orderId}`, 'get', undefined, 'admin');
                if (response.status === 'ok') {
                    setOrder(response.data);
                }
            } catch (error) {
                console.error('Failed to fetch order', error);
            }
        };

        fetchOrder();
    }, [orderId]);

    if (!order) return <p>Loading...</p>;

    return (
        <div className="p-4">
            <Typography variant="h4" gutterBottom>Order Details</Typography>
            <Card>
                <CardContent>
                    <Typography variant="h6">Order ID: {order.orderId}</Typography>
                    <Typography variant="body1">Status: {order.status}</Typography>
                    <Typography variant="body1">Total Price: ${order.totalAmount}</Typography>
                    <Typography variant="body2">Created At: {new Date(order.createdAt).toLocaleDateString()}</Typography>
                    <Typography variant="body2">Updated At: {new Date(order.updatedAt).toLocaleDateString()}</Typography>
                </CardContent>
            </Card>
            <Button onClick={() => navigate('/admin/orders')} variant="contained" color="primary" className="mt-4">
                Back to Orders List
            </Button>
        </div>
    );
};

export default OrderDetails;
