import React, { useEffect, useState } from 'react';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';
import { hrHR } from '@mui/x-data-grid/locales';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../../../API/api';
import { OrderType } from '../../../types/order.type';

const OrdersList: React.FC = () => {
    const [orders, setOrders] = useState<OrderType[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 5 });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const { page, pageSize } = paginationModel;
                const response = await api(`/api/orders?page=${page + 1}&limit=${pageSize}`, 'get', undefined, 'admin');
                if (response.status === 'ok') {
                    setOrders(response.data.data);
                    setTotalCount(response.data.totalCount);
                }
            } catch (error) {
                console.error('Failed to fetch orders', error);
            }
        };

        fetchOrders();
    }, [paginationModel]);

    const handleEdit = (id: number) => {
        navigate(`/admin/orders/edit/${id}`);
    };

    const handleDelete = async (id: number) => {
        try {
            const response = await api(`/api/orders/${id}`, 'delete', undefined, 'admin');
            if (response.status === 'ok') {
                setOrders(orders.filter(order => order.orderId !== id));
            }
        } catch (error) {
            console.error('Failed to delete order', error);
        }
    };

    const transformData = (data: OrderType[]) => {
        return data.map(item => {
            const date = new Date(item.createdAt);
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
            const formattedDate = `${day} ${month} ${year}. godine`;
      
          return {
            orderId: item.orderId, 
            firstName: `${item.users.firstName} ${item.users.lastName}`,
            email: item.users.email,
            totalAmount: item.totalAmount,
            status: item.status,
            createdAt: formattedDate,
          };
        });
      };
      

    const rows = transformData(orders);

    const columns = [
        { field: 'orderId', headerName: 'ID', width: 90 },
        { field: 'firstName', headerName: 'Korisnik', width: 150 },
        { field: 'email', headerName: 'E-Mail', width: 150 },
        { field: 'totalAmount', headerName: 'Ukupna cijena', width: 150 },
        { field: 'status', headerName: 'Status', width: 150 },
        { field: 'createdAt', headerName: 'Datum kreiranja', width: 200 },
        {
            field: 'actions',
            headerName: 'Akcije',
            width: 350,
            renderCell: (params: any) => (
                <>
                    <Button onClick={() => handleEdit(params.row.orderId)} color='secondary'>Izmjeni</Button>
                    <Button onClick={() => handleDelete(params.row.orderId)} color="error">Obriši</Button>
                </>
            ),
        },
    ];

    return (
        <div className="p-3">
            <div className='w-full flex lg:justify-end'>
                <Button onClick={() => navigate("/admin/orders/create")} variant='outlined' color='success'>Kreiraj novu narudžbenicu</Button>
            </div>
            
            <h1 className="text-2xl font-semibold mb-4">Narudžbenice</h1>
            <div className='h-[400px] w-full'>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pagination
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
                    rowCount={totalCount}
                    pageSizeOptions={[5]}
                    getRowId={(row) => row.orderId}
                    localeText={hrHR.components.MuiDataGrid.defaultProps.localeText}
                />
            </div>
        </div>
    );
};

export default OrdersList;
