import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Grid, TextField, Button, CircularProgress, Typography, Box } from '@mui/material';
import api, { ApiResponse } from '../../../API/api';
import { ApiConfig } from '../../../config/api.config';
import { useAuth } from '../../../API/AuthContext';
import { MdEdit } from 'react-icons/md';

interface UserDetailsProps {
  user: any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
}

const UserDetails: React.FC<UserDetailsProps> = ({ user, setUser }) => {
  const { userId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [profileImagePreview, setProfileImagePreview] = useState<string | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setProfileImage(file);
      setProfileImagePreview(URL.createObjectURL(file)); // Set preview URL for the selected file
    }
  };

  const handleSaveChanges = async (event: FormEvent) => {
    event.preventDefault();
    const formData = new FormData();
    if (profileImage) {
      formData.append('profileImage', profileImage);
    }
    formData.append('firstName', user.firstName);
    formData.append('lastName', user.lastName);
    formData.append('email', user.email);
    formData.append('role', user.role);

    try {
      setLoading(true);
      setError(null);

      const response: ApiResponse = await api(
        `/api/users/${userId}`,
        'put',
        formData,
        'user',
        { useMultipartFormData: true }
      );

      if (response.status === 'ok') {
        setUser({ ...user, profileImage: response.data.profileImage });
        console.log('User details updated successfully');
      } else {
        setError('Greška prilikom snimanja detalja korisnika!');
      }
    } catch (err) {
      setError('Došlo je do greške prilikom ažuriranja detalja korisnika!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      
      <form onSubmit={handleSaveChanges}>
        <Grid container spacing={3}>
          {/* Slika profila i upload sekcija */}
          <Grid item xs={12} sm={4} className='flex items-center' style={{ textAlign: 'center' }}>
            <Box
              position="relative"
              width="150px"
              height="150px"
              margin="auto"
              sx={{ '&:hover .overlay': { opacity: 1 } }}
            >
              <img
                src={profileImagePreview ? profileImagePreview : (user.profileImage ? `${ApiConfig.API_URL}/uploads/users/${userId}/${user.profileImage}` : '/default-profile.png')}
                alt="Profile"
                className="w-32 h-32 rounded-full object-cover"
                style={{
                  borderRadius: '50%',
                  width: '150px',
                  height: '150px',
                  objectFit: 'cover',
                  border: '4px solid #c59d5f',
                }}
              />
              <Box
                className="overlay"
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                bgcolor="rgba(0,0,0,0.5)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                sx={{ opacity: 0, transition: 'opacity 0.3s' }}
              >
                <label htmlFor="profile-image-upload" style={{ cursor: 'pointer' }}>
                  <Typography color="white" variant="button">
                    Promijeni sliku
                  </Typography>
                </label>
              </Box>
                <Box
                  position="absolute"
                  top="8px"
                  right="8px"
                  bgcolor="#fff"
                  borderRadius="50%"
                  width="30px"
                  height="30px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  border="2px solid #c59d5f"
                >
                <label htmlFor="profile-image-upload" style={{ cursor: 'pointer' }}>
                  <MdEdit size={20} color="#c59d5f" />
                </label>
              </Box>
            </Box>
            <input
              type="file"
              accept="image/*"
              id="profile-image-upload"
              onChange={handleFileChange}
              className="hidden"
            />
          </Grid>

          {/* Forma za unos podataka */}
          <Grid item xs={12} sm={8}>
          <Typography variant="h6" gutterBottom align="left">
            Detalji korisnika
          </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Ime"
                  variant="outlined"
                  value={user.firstName}
                  onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Prezime"
                  variant="outlined"
                  value={user.lastName}
                  onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={user.email}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  margin="normal"
                  required
                  type="email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Role"
                  variant="outlined"
                  value={user.role}
                  disabled
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {error && <Typography color="error">{error}</Typography>}
        <button
          type="submit"
          disabled={loading}
          className='px-6 py-2 w-full mt-4 hover:bg-black hover:text-white'
          style={{ 
            border: '1px solid black'
           }}
        >
          {loading ? <CircularProgress size={24} /> : 'Spasi promjene'}
        </button>
      </form>
    </>
  );
};

export default UserDetails;
