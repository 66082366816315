import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import api from '../../API/api';
import { ApiConfig } from '../../config/api.config';
import HeroSlider, { Slide } from 'hero-slider';
import Navbar from '../Navbar';

// Definicija interfejsa za Hero podatke
interface Hero {
    heroId: number;
    title: string;
    shortDescription: string;
    description: string;
    imageUrl: string;
    status: 'active' | 'inactive';
}

const CustomHeroSlider: React.FC = () => {
    const [heroes, setHeroes] = useState<Hero[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchHeroes = async () => {
            try {
                const response = await api('/api/hero', 'get', {});
                const filteredHero = response.data.filter((hero: { status: string; }) => hero.status === 'active');
                setHeroes(filteredHero);
            } catch (error) {
                console.error('Error fetching heroes:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchHeroes();
    }, []);

    if (loading) {
        return (
            <div className="relative w-full h-[50vh] flex items-center justify-center bg-gray-200">
                <div className="w-full h-full flex flex-col items-center justify-center space-y-4 p-4">
                    {/* Skeleton loader za slike */}
                    <div className="w-full h-full max-w-screen-lg bg-gray-300 animate-pulse rounded-lg"></div>
                </div>
            </div>
        );
    }

    return (
        <div className="relative">
            <HeroSlider
                height="62vh"
                autoplay
                controller={{
                    initialSlide: 1,
                    slidingDuration: 1500,
                    slidingDelay: 100,
                    onSliding: (nextSlide: number) => console.debug("onSliding(nextSlide): ", nextSlide),
                    onBeforeSliding: (previousSlide: number, nextSlide: number) => console.debug("onBeforeSliding(previousSlide, nextSlide): ", previousSlide, nextSlide),
                    onAfterSliding: (nextSlide: number) => console.debug("onAfterSliding(nextSlide): ", nextSlide)
                }}
            >
                {heroes.map((hero) => (
                    <Slide
                        key={hero.heroId}
                        background={{
                            backgroundImageSrc: ApiConfig.API_URL + hero.imageUrl,
                            backgroundAnimation: "zoom"
                        }}
                    >
                      <div className="absolute inset-0 flex items-center justify-center text-white text-center bg-black bg-opacity-60">
                          <div className="w-full mx-auto">
                          <h1 className="text-[8vw] lg:text-[66px] tracking-tight leading-tight animate-fade-in-up font-Quentin whitespace-nowrap">
                            {hero.title}
                            </h1>

                            <div className="flex items-center justify-center">
                                <span className="lg:w-6 lg:h-6 w-1 h-1 bg-white rounded-full inline-block animate-fade-in-up delay-500 mr-3 lg:mr-7"></span>
                                    <p className="text-md lg:text-[100px] font-bold font-Montserrat tracking-[15px] lg:tracking-[30px] animate-fade-in-up delay-500 uppercase">
                                        {hero.shortDescription}
                                    </p>
                                <span className="lg:w-6 lg:h-6 w-1 h-1 bg-white rounded-full inline-block animate-fade-in-up delay-500"></span>
                            </div>


                          </div>
                      </div>    
                    </Slide>
                ))}
            </HeroSlider>
        </div>
    );
};

export default CustomHeroSlider;
