import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from '../../API/api';
import { BlogPost } from '../../interfaces/blog-posts.interface';
import { ApiConfig } from '../../config/api.config';
import BlogComments from '../Comments/BlogComments';
import styles from './BlogPostDetails.module.css';

interface CategoriesCont {
  name: string;
  count: number;
}

const BlogPostDetail: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [blogPost, setBlogPost] = useState<BlogPost | null>(null);
  const [categories, setCategories] = useState<CategoriesCont[]>([]);
  const [tags, setTags] = useState<[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await api(`/api/blog-posts/${slug}`, 'get', {}, 'guest');
        if (response.status === 'ok') {
          setBlogPost(response.data.blogPost);
          setCategories(response.data.categories);
          setTags(response.data.tags);
        } else {
          console.error('Failed to fetch blog post details!');
        }
      } catch (error) {
        console.error('There was an error fetching the blog post details!', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [slug]);

  if (loading) return (
    <section className="py-12">
      <div className="container mx-auto px-4 md:px-8 lg:px-16">
        <div className="animate-pulse">
          <div className="h-60 bg-gray-300 rounded-lg mb-6"></div>
          <div className="space-y-4">
            <div className="h-6 bg-gray-300 rounded"></div>
            <div className="h-4 bg-gray-300 rounded"></div>
            <div className="h-4 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
    </section>
  );

  return blogPost ? (
    <section className="py-4 bg-gray-50">
      <div className="container mx-auto px-4 md:px-8 lg:px-16 flex flex-col lg:flex-row">
        {/* Glavni sadržaj */}
        <div className="lg:w-3/4 lg:pr-8">
          <img
            src={`${ApiConfig.API_URL}/uploads/blogs/${blogPost.blogPostsId}/${blogPost.imageUrl}`}
            alt={blogPost.title}
            className="w-full h-[300px] md:h-[400px] lg:h-[500px] object-cover rounded-lg shadow-md mb-9"
          />
          <p className="tracking-widest uppercase text-xs mb-2">
            {new Date(blogPost.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })} &bull;
            <span className="font-semibold text-gray-800"> {blogPost.category.name}</span> &bull;
            <span className="text-gray-600"> {blogPost.author.firstName} {blogPost.author.lastName}</span>
          </p>
          <h1 className="text-3xl uppercase font-bold text-gray-900 mb-6">{blogPost.title}</h1>
          <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: blogPost.content }}
            />

          <section className="bg-white rounded-xl shadow-lg mt-8 p-6 transition-all duration-300 hover:shadow-xl">
            <div className="flex flex-col lg:grid lg:grid-cols-[auto_1fr_auto] lg:gap-8 items-center lg:items-start">
              
              {/* Profilna slika */}
              <div className="mb-4 lg:mb-0 lg:flex lg:items-center lg:h-full">
                <img
                  src={`${ApiConfig.API_URL}/uploads/users/${blogPost.author.usersId}/${blogPost.author.profileImage}`}
                  alt={`${blogPost.author.firstName} {blogPost.author.lastName}`}
                  className="object-cover rounded-full h-20 w-20 border-2 border-gray-300 shadow-sm transition-transform duration-200 hover:scale-105"
                />
              </div>

              {/* Informacije o autoru */}
              <div className="flex flex-col text-center lg:text-left lg:flex-grow lg:h-full lg:justify-center">
                <h3 className="text-lg lg:text-xl font-bold text-gray-900 tracking-tight mb-1">
                  {blogPost.author.firstName} {blogPost.author.lastName}
                </h3>
                <p className="text-gray-600 text-sm lg:text-base mb-1">
                  <a href={`mailto:${blogPost.author.email}`} className="text-customGold-500 hover:text-customGold-700 transition-colors duration-200">
                    {blogPost.author.email}
                  </a>
                </p>
                <p className="text-gray-500 text-xs lg:text-sm">{blogPost.author.role}</p>
              </div>

              {/* Komentar autora */}
              {blogPost.authorComment && (
                <div className="mt-4 lg:mt-0 lg:ml-8 text-gray-800 text-sm lg:max-w-lg text-center lg:text-left">
                  <blockquote className="italic leading-relaxed lg:border-l-4 lg:border-customGold-500 lg:pl-4">
                    "{blogPost.authorComment}"
                  </blockquote>
                </div>
              )}
            </div>
          </section>
          <BlogComments blogPostsId={blogPost.blogPostsId} />
        </div>

        {/* Sidebar */}
        <aside className="lg:w-1/4 mt-6 lg:mt-0">
        <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
          <h2 className="text-md uppercase tracking-widest font-bold mb-2">Kategorije</h2>
          <ul className="space-y-2">
            {categories.map((category, index) => (
              <li key={index} className="flex justify-between text-gray-700 uppercase text-xs tracking-widest">
                <Link to={`/blog/category/${category.name}`} className="hover:text-customGold-600 ">
                  {category.name}
                </Link>
                <span className="text-gray-500">({category.count})</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6">
        <h2 className="text-md font-bold mb-2 uppercase tracking-widest">Tagovi</h2>
        <ul className="flex flex-wrap space-x-2 text-sm">
          {tags.map((tag, index) => (
            <li key={index} className="text-gray-700">
              <Link to={`/blog/tag/${tag}`} className="hover:text-customGold-600">
                {tag}
              </Link>
              {index < tags.length - 1 && <span className="text-gray-400">,</span>}
            </li>
          ))}
        </ul>
      </div>

      </aside>
      </div>
    </section>
  ) : (
    <section className="py-16">
      <div className="container mx-auto px-4">
        <div className="text-center text-gray-600">Blog nije pronađen</div>
      </div>
    </section>
  );
};

export default BlogPostDetail;
