import React, { useEffect, useState } from 'react';
import { useAuth } from '../../API/AuthContext';
import api from '../../API/api';
import { Tab, Tabs, Box } from '@mui/material';
import { TabPanel } from './TabPanel';
import UserDetails from './TabPanels/UserDetails';
import UserAppointments from './TabPanels/UserAppintments';
import UserComments from './TabPanels/UserComments';
import UserNotifications from './TabPanels/UserNotifications';
import UserPoints from './TabPanels/UserPoints';

const Profile: React.FC = () => {
  const { userId } = useAuth();
  const [user, setUser] = useState<any>(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api(`/api/users/${userId}`, 'get', {});
        setUser(response.data);
      } catch (error) {
        console.error('Greška pri preuzimanju podataka o korisniku:', error);
      }
    };

    fetchUserData();
  }, [userId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Box 
      sx={{
        width: '100%',
        padding: { xs: 2, sm: 3, md: 4, lg: 5 }, 
        maxWidth: '1200px', 
        margin: '0 auto',
      }}
    >
        <div className='bg-[#ffffff] p-2 rounded-md shadow-lg'>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="profile tabs">
                <Tab label="Osnovni detalji" />
                <Tab label="Rezervacije" />
                <Tab label="Komentari" />
                <Tab label="Poeni" />
                <Tab label="Notifikacije" />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
                {user && <UserDetails user={user} setUser={setUser} />}
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                {user && <UserAppointments appointments={user.appointments} />}
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                {user && <UserComments comments={user.comments} />}
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
                {user && <UserPoints points={user.loyaltyPoints} />}
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
                {user && <UserNotifications notifications={user.notifications} />}
            </TabPanel>
      </div>
    </Box>
  );
};

export default Profile;
