import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../API/api';
import { BlogPost } from '../../interfaces/blog-posts.interface';
import { ApiConfig } from '../../config/api.config';

const SkeletonLoader: React.FC = () => (
  <div className="flex flex-col md:flex-row justify-center gap-4 m-2">
    {Array.from({ length: 3 }).map((_, index) => (
      <div key={index} className="bg-white shadow-lg rounded-lg p-6">
        <div className="h-48 w-80 bg-gray-300 animate-pulse rounded mb-4"></div>
        <div className="space-y-4">
          <div className="h-6 bg-gray-300 animate-pulse rounded w-3/4 mb-2"></div>
          <div className="h-4 bg-gray-300 animate-pulse rounded w-2/3 mb-2"></div>
          <div className="h-4 bg-gray-300 animate-pulse rounded w-1/2"></div>
        </div>
      </div>
    ))}
  </div>
);

const BlogPosts: React.FC = () => {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    api('/api/blog-posts', 'get', {}, 'guest')
      .then((response: any) => {
        if (response.status === 'ok') {
          const publishedBlogs = response.data.data.filter((post: any) => post.status === 'published');
          const latestPosts = publishedBlogs.slice(0,3);
          setBlogPosts(latestPosts);
        } else {
          console.error('Failed to fetch blog posts!');
        }
      })
      .catch((error: any) => {
        console.error('There was an error fetching the blog posts!', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleReadMore = (slug: string) => {
    navigate(`/blog/${slug}`);
  };

  return (
    <section className="py-16 bg-gray-100 text-center">
  <h2 className="text-4xl font-bold mb-6">Posljednji blogovi</h2>
  {loading ? (
    <SkeletonLoader />
  ) : (
    <div className="flex flex-col md:flex-row justify-center items-center gap-4 p-4 h-auto">
      {blogPosts.map(post => (
        <div 
          key={post.blogPostsId} 
          className="group bg-white shadow-lg rounded-lg w-full lg:w-80 transform hover:-translate-y-2 transition-transform duration-200 flex flex-col justify-between min-h-[370px]"
        >
          <div className="overflow-hidden h-48 rounded-t-lg">
            <img 
              src={ApiConfig.API_URL + "/uploads/blogs/" + post.blogPostsId + "/small-" + post.imageUrl} 
              alt={post.title} 
              className="w-full h-full object-cover object-center transition-transform duration-200 group-hover:scale-105" 
            />
          </div>
          <div className="flex-grow flex items-center justify-center p-4 text-center text-sm">
            <p className="text-gray-700">{post.excerpt.slice(0, 100)}...</p>
          </div>
          <div className="p-4">
            <button
              className="px-10 py-2 w-full text-xs font-bold tracking-[2px] border-[1px] border-black hover:bg-black text-black hover:text-white uppercase"
              onClick={() => handleReadMore(post.slug)}
            >
              Saznaj više
            </button>
          </div>
        </div>
      ))}
    </div>
  )}
</section>


  );
};

export default BlogPosts;
