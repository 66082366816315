import React, { useEffect, useRef, useState } from 'react';
import HeroSlider from './Hero/HeroSlider';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Section2 from './StaticSectionDisplay/section-2';
import WorkingOurs from './WorkingOurs/WorkingOurs';
import BlogPosts from './BlogPosts/BlogPost';
import RandomDiscountedProduct from './Shop/client/RandomDiscountedProduct';
import ContactSection from './Contact/ContactSection';

const HomeView: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // Prikazivanje sekcije kad je 50% vidljiva
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      
      {/* Hero Section */}
      <div className='min-h-screen'>
         <HeroSlider />
      
      
      {/* Our Story Section */}
      <Section2 />
      {/* Working Hours Section */}
      </div>
      <WorkingOurs />

      <BlogPosts />

      {/* Discount Section */}
      <section
          ref={sectionRef}
          className={`py-16 text-white text-center flex flex-col min-h-[30vh] justify-center relative overflow-hidden ${
            isVisible ? 'opacity-100' : 'opacity-0'
          } transition-opacity duration-1000`}
        >
          <div className="absolute inset-0">
            <div
              className={`w-full h-full absolute top-0 left-0 bg-gradient-to-r from-[#8b6a3c] to-[#111827] opacity-60`}
            />
          </div>
          <div className="relative z-10">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">
              Otkrijte Naše Najnovije Ponude!
            </h1>
            <p className="text-lg mb-6">
              Iskoristite nevjerojatne popuste na odabrane proizvode. Posjetite naš shop i pronađite savršene proizvode za vas!
            </p>
            <a
              href="/shop" // Zamijenite sa stvarnom putanjom do shopa
              className="inline-block bg-yellow-500 text-black font-semibold py-2 px-6 hover:bg-yellow-400 transition-colors"
            >
              Posjetite Shop
            </a>
          </div>
        </section>

      {/* Perfect Hair Care Section */}
      <div className='bg-white'>
        <RandomDiscountedProduct /></div>
      {/* Contact and Map Section */}
      <ContactSection />
    </div>
  );
};

export default HomeView;
