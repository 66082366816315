// src/components/AdminDashboard.tsx
import React from 'react';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';

const AdminDashboard: React.FC = () => {
  return (
    <div className="flex">
      <Sidebar />
      <div className="md:ml-14 p-4 w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminDashboard;
