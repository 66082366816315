import React from 'react';

const CommentsManagement: React.FC = () => {
  return (
    <div className="comments-management p-4">
      <h1 className="text-2xl font-semibold mb-4">Manage Comments</h1>
      {/* Dodajte funkcionalnosti za upravljanje komentarima */}
    </div>
  );
};

export default CommentsManagement;
